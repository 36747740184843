import { Box, VStack, Flex, Heading, Text, Center } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetProject } from "../../Redux/AppReducer/Action";
import ProjectCard from "./ProjectCard";

const ProjectCardPage = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  const useId = useSelector((state) => state?.auth?.user?.id);

  useEffect(() => {
    dispatch(GetProject(token, useId));
  }, [dispatch, token, useId]);

  const getAllProject = useSelector((state) => state.app.GETPROJECTs);

  const completedPercentage = 30;
  const pendingPercentage = 70;

  return (
    <VStack
      w="full"
      spacing="3"
      boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
      borderRadius={7}
      align="start"
      p="3"
    >
      <Heading size="md">All Works</Heading>
      <Box w="full">
        <Box display="flex" justifyContent="space-between">
          <Text fontSize="sm" color="green.600">
            Completed: {completedPercentage}%
          </Text>
          <Text fontSize="sm" color="orange.500">
            Pending: {pendingPercentage}%
          </Text>
        </Box>
        <Box
          width="100%"
          height="10px"
          borderRadius="md"
          bg="orange.500"
          overflow="hidden"
        >
          <Box height="100%" width={`${completedPercentage}%`} bg="green.500" />
          <Box height="100%" width={`${pendingPercentage}%`} />
        </Box>
      </Box>
      <Flex
        w="full"
        boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
        p="3"
        bg="#4b4b4b"
        color="white"
        borderRadius="md"
        justifyContent="space-between"
      >
        <Center w="20%">
          <Text>S.No.</Text>
        </Center>
        <Center w="20%">
          <Text>Project ID</Text>
        </Center>
        <Center w="20%">
          <Text> Project Name</Text>
        </Center>
        <Center w="20%">
          <Text>Assignee</Text>
        </Center>
        <Center w="20%">
          <Text>Status</Text>
        </Center>
      </Flex>
      {getAllProject && getAllProject?.projects?.length > 0 ? (
        getAllProject?.projects.map((project, index) => (
          <ProjectCard key={project?._id} project={project} index={index + 1} />
        ))
      ) : (
        <Text>No Projects Found</Text>
      )}
    </VStack>
  );
};

export default ProjectCardPage;
