import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  useToast,
  IconButton,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  HStack,
  FormLabel,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
// import Navbar from "./Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteComment,
  EditComment,
  EditDescription,
  GetProject,
  SaveComment,
  SaveDescription,
} from "../../Redux/AppReducer/Action";
import {
  IoChevronDownOutline,
  IoTimerOutline,
  IoCloseSharp,
  IoClose,
} from "react-icons/io5";
import { FaCheck, FaLink } from "react-icons/fa6";
import { GrAttachment } from "react-icons/gr";
import { AiOutlinePartition } from "react-icons/ai";

import Mention from "quill-mention";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";

Quill.register("modules/mention", Mention);

const UserProject = ({ isSidebarExpanded }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getAllProject = useSelector((state) => state.app.GETPROJECTs);

  const [currentProject, setCurrentProject] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [isEditingComment, setIsEditingComment] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [options, setOptions] = useState(["FINANCE READY", "DONE"]);
  const [selectedOption, setSelectedOption] = useState("TO DO");
  const [selectedAssignees, setSelectedAssignees] = useState({});

  const [projectNames, setProjectNames] = useState({});
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editingCommentText, setEditingCommentText] = useState("");
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");

  const handleSaveProjectName = () => {
    setIsEditingName(false);
  };

  const handleSaveProjectDescription = () => {
    if (description.trim() && currentProject?.leadId) {
      dispatch(EditDescription(token, currentProject.leadId, description));
      dispatch(GetProject(token));
      toast({
        title: "Project description saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setCurrentProject((prev) => ({
        ...prev,
        description,
      }));
      setIsEditingDescription(false);
    } else {
      toast({
        title: "Description cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const colorMapping = {
    "TO DO": "gray",
    "FINANCE READY": "blue",
    DONE: "green",
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setOptions((prevOptions) =>
      prevOptions.filter((item) => item !== option).concat(selectedOption)
    );
  };

  const toast = useToast();

  useEffect(() => {
    dispatch(GetProject(token));
  }, [dispatch, token]);

  const handleProjectClick = (project) => {
    console.log(project);
    setCurrentProject(project);
    setComments(project.comments || []);
    setIsTableVisible(false);
    setProjectName(projectNames[project.leadId] || project.projectName || "");
    setDescription(project.description || "");
  };

  const handleCloseProjectDetails = () => {
    setCurrentProject(null);
    setIsTableVisible(true);
  };

  const userId = useSelector((state) => state.auth.user.id);

  const sanitizeComment = (text) => {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.body.textContent || "";
  };

  const handleAddOrEditComment = () => {
    if (newComment.trim()) {
      // const sanitizedComment = sanitizeComment(newComment);
      const updatedComments =
        isEditing !== null
          ? comments.map((comment, index) =>
              index === isEditing
                ? {
                    ...comment,
                    text: newComment,
                    date: new Date().toLocaleString(),
                  }
                : comment
            )
          : [
              { text: newComment, date: new Date().toLocaleString() },
              ...comments,
            ];
      dispatch(SaveComment(token, currentProject.leadId, userId, newComment));

      setComments((prevComments) => [updatedComments, ...prevComments]);
      setNewComment("");
      setIsEditing(null);
    } else {
      toast({
        title: "Comment cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditComment = (index) => {
    setIsEditing(index);
    setEditingCommentText(comments[index].commentText);
  };

  const handleDeleteComment = (index) => {
    const commentId = currentProject.comments[index]?._id;
    console.log("Comment ID being deleted: ", commentId);
    const updatedComments = comments.filter((_, i) => i !== index);
    dispatch(deleteComment(token, currentProject.leadId, commentId));
    setComments(updatedComments);
    toast({
      title: "Comment deleted.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const users = useMemo(
    () => [
      { id: 1, value: "Sridhar" },
      { id: 2, value: "Prakruthi" },
      { id: 3, value: "Ansu Kumari" },
      { id: 4, value: "Nithin Bangera" },
    ],
    []
  );

  const mentionModule = useMemo(
    () => ({
      toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
      ],
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@"],
        source: (searchTerm, renderList) => {
          const matches = users.filter((user) =>
            user.value.toLowerCase().includes(searchTerm.toLowerCase())
          );
          renderList(matches, searchTerm);
        },
      },
    }),
    [users]
  );

  const assignee = ["Unassigned", "User 1", "User 2", "User 3", "User 4"];

  const filteredAssignee = assignee.filter((assignee) =>
    assignee.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleAssigneeSelect = (projectId, assignee) => {
    setSelectedAssignees((prev) => ({
      ...prev,
      [projectId]: assignee,
    }));
    setSearchInput("");
  };

  // Function to get initials from name
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((part) => part[0].toUpperCase())
      .join("");
  };

  const getBadgeColorFromName = (name = "Unassigned") => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, "0")}${(
      (hash >> 16) &
      0xff
    )
      .toString(16)
      .padStart(2, "0")}${((hash >> 8) & 0xff).toString(16).padStart(2, "0")}`;
    return color.slice(0, 7);
  };

  const handleSaveEditedComment = async () => {
    if (editingCommentText.trim()) {
      // const sanitizedComment = sanitizeComment(editingCommentText);
      const commentId = currentProject.comments[isEditing]?._id;

      console.log("Comment ID being edited:", commentId);

      try {
        await dispatch(
          EditComment(
            token,
            currentProject.leadId,
            commentId,
            editingCommentText
          )
        );

        const updatedComments = comments.map((comment, index) =>
          index === isEditing
            ? {
                ...comment,
                text: editingCommentText,
                isEdited: true,
                editedAt: new Date().toISOString(),
              }
            : comment
        );

        setComments(updatedComments);
        setIsEditing(null);
        setEditingCommentText("");
      } catch (error) {
        console.error("Error editing comment:", error);
      }
    } else {
      toast({
        title: "Comment cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const user = {
    firstName: "Sri",
    lastName: "Ram",
  };

  return (
    <Box>
      {isTableVisible && (
        <Box boxShadow="0 0 10px rgba(128, 128, 128, 0.5)" borderRadius={7}>
          <Flex p={3} mb={4}>
            <Heading
              color={"#151617"}
              as="h5"
              fontSize={"18px"}
              fontWeight={"bold"}
              fontFamily={"Public Sans, sans-serif"}
            >
              {getAllProject?.leads?.length} record
              {getAllProject?.leads?.length !== 1 ? "s" : ""} found
            </Heading>
          </Flex>
          <TableContainer>
            <Table mt={2}>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th>
                    <Checkbox />
                  </Th>
                  <Th color={"white"}>#</Th>
                  <Th color={"white"}>ID</Th>
                  <Th color={"white"}>Project Name</Th>
                  <Th color={"white"}>Assignee</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {getAllProject?.projects?.map((Leads, index) => (
                  <Tr key={index}>
                    <Td>
                      <Checkbox />
                    </Td>
                    <Td>{index + 1}</Td>
                    <Td
                      onClick={() => handleProjectClick(Leads)}
                      cursor={"pointer"}
                      color={"blue.500"}
                    >
                      {Leads.leadId}
                    </Td>
                    <Td>{Leads.name}</Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          width="auto"
                          as={Button}
                          textAlign="left"
                          color={"#6f6b7d"}
                          background={"none"}
                          _hover={{ bg: "none", border: "none" }}
                          border="none"
                          focusBorderColor="none"
                        >
                          <Box display="flex" alignItems="center">
                            {selectedAssignees[Leads.leadId] !==
                              "Unassigned" && (
                              <Box
                                width="30px"
                                height="30px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="full"
                                backgroundColor={getBadgeColorFromName(
                                  selectedAssignees[Leads.leadId]
                                )}
                                color="white"
                                fontWeight="bold"
                                mr={2}
                              >
                                {getInitials(
                                  selectedAssignees[Leads.leadId] ||
                                    "Unassigned"
                                )}
                              </Box>
                            )}
                            {selectedAssignees[Leads.leadId] || "Unassigned"}
                          </Box>
                        </MenuButton>
                        <MenuList maxHeight="200px" overflowY="auto">
                          <Box p={2}>
                            <Input
                              placeholder="Select..."
                              value={searchInput}
                              onChange={(e) => setSearchInput(e.target.value)}
                            />
                          </Box>
                          {filteredAssignee.length > 0 ? (
                            filteredAssignee.map((assignee, index) => (
                              <MenuItem
                                _hover={{
                                  bg: "#D8000026",
                                  textColor: "#D80000",
                                }}
                                key={index}
                                onClick={() =>
                                  handleAssigneeSelect(Leads.leadId, assignee)
                                }
                              >
                                {assignee}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No matches found</MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    </Td>
                    <Td>
                      <Flex gap={5}>
                        {/* Display user initials in a circular badge */}
                        <Box
                          width="35px"
                          height="35px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="full"
                          backgroundColor="gray.500"
                          color="white"
                          fontWeight="bold"
                        >
                          {`${user.firstName[0]}${user.lastName[0]}`}
                        </Box>
                        <Box mt={2}>
                          {new Date(Leads.createdAt).toLocaleString()}
                        </Box>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {currentProject && (
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          // borderRadius={7}
          position={"relative"}
          top={73}
          pl={6}
          pb={6}
          pr={6}
        >
          <Flex>
            <Box w="70%" h="535px" overflowY="auto" pr={4} position="relative">
              <Box
                as="h4"
                fontSize={20}
                fontWeight="bold"
                color="grey"
                textAlign="left"
                mb={8}
                bg="white"
                zIndex={1}
                borderBottom="1px solid #e2e8f0"
                p={2}
              >
                Project Details
              </Box>
              <VStack spacing={4}>
                <Box w={"full"} mb={2}>
                  {isEditingName ? (
                    <HStack spacing={4}>
                      <Input
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        autoFocus
                      />
                      <IconButton
                        icon={<FaCheck />}
                        onClick={handleSaveProjectName}
                        aria-label="Save"
                      />
                      <IconButton
                        icon={<IoCloseSharp size={20} />}
                        onClick={() => setIsEditingName(false)}
                        aria-label="Cancel"
                      />
                    </HStack>
                  ) : (
                    <Heading size="md" onClick={() => setIsEditingName(true)}>
                      {projectName || "Unnamed Project"}
                    </Heading>
                  )}
                </Box>
                <Box w={"full"}>
                  <Flex gap={4}>
                    <Button h={8}>
                      <GrAttachment />
                      Attach
                    </Button>
                    <Button h={8}>
                      <AiOutlinePartition />
                      Add a child issue
                    </Button>
                    <Button h={8}>
                      <FaLink />
                      Link Issue
                    </Button>
                    <Button h={8}>Create</Button>
                    <Button h={8}>
                      <IoTimerOutline />
                      Start work
                    </Button>
                  </Flex>
                </Box>
                <Box w="full" h="auto">
                  <FormLabel as={"h3"} fontWeight={"bold"}>
                    Description :
                  </FormLabel>
                  {isEditingDescription ? (
                    <>
                      <ReactQuill
                        value={description}
                        onChange={(content) => setDescription(content)}
                        modules={mentionModule}
                        style={{ marginLeft: "8px", marginRight: "8px" }}
                        autoFocus
                      />
                      <HStack ml={2} mt={2} spacing={4}>
                        <Button onClick={handleSaveProjectDescription}>
                          Save
                        </Button>
                        <Button onClick={() => setIsEditingDescription(false)}>
                          Cancel
                        </Button>
                      </HStack>
                    </>
                  ) : (
                    <Box
                      onClick={() => setIsEditingDescription(true)}
                      _hover={{ cursor: "pointer", bg: "#f5f5f5" }}
                      p={3}
                      ml={2}
                      mr={2}
                      borderRadius={5}
                      borderWidth={1}
                    >
                      <Text
                        pl={4}
                        dangerouslySetInnerHTML={{
                          __html: description || "Add project description...",
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <Box w="full">
                  <FormLabel as="h3" fontWeight="bold">
                    Comments :
                  </FormLabel>
                  <Box pl={2} pr={2}>
                    {!isEditingComment ? (
                      <Textarea
                        placeholder="Click to add a comment..."
                        onClick={() => setIsEditingComment(true)}
                        value={newComment}
                        isReadOnly
                        _hover={{ cursor: "pointer" }}
                        minH="40px"
                      />
                    ) : (
                      <>
                        <ReactQuill
                          theme="snow"
                          value={newComment}
                          onChange={setNewComment}
                          modules={mentionModule}
                          placeholder="Add a comment with @mention..."
                          style={{ minHeight: "120px" }}
                        />
                        <HStack spacing={4} mt={2}>
                          <Button onClick={handleAddOrEditComment}>Save</Button>
                          <Button
                            onClick={() => {
                              setNewComment("");
                              // setIsEditingComment(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </HStack>
                      </>
                    )}
                  </Box>
                  {comments.length > 0 ? (
                    comments.reverse().map((comment, index) => {
                      // console.log(comment,comments)
                      return (
                        <Box key={index} my={4} p={4}>
                          {isEditing === index ? (
                            <>
                              <ReactQuill
                                theme="snow"
                                modules={mentionModule}
                                value={editingCommentText}
                                onChange={setEditingCommentText}
                              />
                              <HStack mt={2} spacing={4}>
                                <Button onClick={handleSaveEditedComment}>
                                  Save
                                </Button>
                                <Button onClick={() => setIsEditing(null)}>
                                  Cancel
                                </Button>
                              </HStack>
                            </>
                          ) : (
                            <>
                              <HStack mb={2}>
                                <Text fontWeight="bold" fontSize="md" ml={4}>
                                  {comment.userName || "Sridhar"}
                                </Text>
                                <Text fontSize="sm" color="gray.500">
                                  {new Date(comment.createdAt).toLocaleString()}
                                  {comment.isEdited && (
                                    <Text
                                      as="span"
                                      fontSize="xs"
                                      color="gray.400"
                                      ml={2}
                                    >
                                      (Edited)
                                    </Text>
                                  )}
                                </Text>
                              </HStack>
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: comment.commentText,
                                }}
                                mb={2}
                                pl={4}
                                ml={4}
                              />

                              <HStack
                                mt={2}
                                ml={3}
                                justifyContent="flex-start"
                                spacing={4}
                              >
                                <Button
                                  variant="link"
                                  onClick={() => handleEditComment(index)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="link"
                                  ml={2}
                                  onClick={() => handleDeleteComment(index)}
                                >
                                  Delete
                                </Button>
                              </HStack>
                            </>
                          )}
                        </Box>
                      );
                    })
                  ) : (
                    <Text pl={2}>No comments yet.</Text>
                  )}
                </Box>
              </VStack>
            </Box>
            <Box w="30%" h="535px" overflowY="auto" p={6} borderLeftWidth={1}>
              <Box
                as="span"
                position="absolute"
                top="10px"
                right="4px"
                cursor="pointer"
                onClick={handleCloseProjectDetails}
              >
                <IoClose size={25} />
              </Box>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  colorScheme={colorMapping[selectedOption] || "gray"}
                  h={8}
                  fontSize={14}
                >
                  {selectedOption}
                </MenuButton>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleOptionSelect(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Box mt={4} border={"1px solid #DFE1E6"}>
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    <h2>
                      <AccordionButton p={4} borderBottom={"1px solid #DFE1E6"}>
                        <Heading
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize={18}
                        >
                          Details
                        </Heading>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p={4}>
                      {currentProject && (
                        <Box>
                          <Text>
                            <FormLabel>Assignee:</FormLabel>
                            <Menu>
                              <MenuButton
                                width="auto"
                                as={Button}
                                textAlign="left"
                                color={"#6f6b7d"}
                                background={"none"}
                                _hover={{ bg: "none", border: "none" }}
                                border="none"
                                focusBorderColor="none"
                              >
                                <Box display="flex" alignItems="center">
                                  {selectedAssignees[currentProject.leadId] !==
                                    "Unassigned" && (
                                    <Box
                                      width="30px"
                                      height="30px"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      borderRadius="full"
                                      backgroundColor={getBadgeColorFromName(
                                        selectedAssignees[currentProject.leadId]
                                      )}
                                      color="white"
                                      fontWeight="bold"
                                      mr={2}
                                    >
                                      {getInitials(
                                        selectedAssignees[
                                          currentProject.leadId
                                        ] || "Unassigned"
                                      )}
                                    </Box>
                                  )}
                                  {selectedAssignees[currentProject.leadId] ||
                                    "Unassigned"}
                                </Box>
                              </MenuButton>
                              <MenuList maxHeight="200px" overflowY="auto">
                                <Box p={2}>
                                  <Input
                                    placeholder="Select..."
                                    value={searchInput}
                                    onChange={(e) =>
                                      setSearchInput(e.target.value)
                                    }
                                  />
                                </Box>
                                {filteredAssignee.length > 0 ? (
                                  filteredAssignee.map((assignee, index) => (
                                    <MenuItem
                                      _hover={{
                                        bg: "#D8000026",
                                        textColor: "#D80000",
                                      }}
                                      key={index}
                                      onClick={() =>
                                        handleAssigneeSelect(
                                          currentProject.leadId,
                                          assignee
                                        )
                                      }
                                    >
                                      {assignee}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem disabled>No matches found</MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          </Text>
                          <Flex>
                            <FormLabel mt={3}>Reporter:</FormLabel>
                            <Text>{currentProject.reporter}</Text>
                          </Flex>
                          <Flex>
                            <FormLabel mt={3}>Created Date:</FormLabel>
                            <Text mt={3}>{currentProject.createdAt}</Text>
                          </Flex>
                          {/* <Flex>
                              <FormLabel mt={3}>Created Time:</FormLabel>
                              <Text mt={3}>{currentProject.createdAt}</Text>
                            </Flex> */}
                        </Box>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
              <Box mt={4} border={"1px solid #DFE1E6"}>
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    <h2>
                      <AccordionButton p={4} borderBottom={"1px solid #DFE1E6"}>
                        <Heading
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize={18}
                        >
                          Client Details
                        </Heading>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p={4}>
                      {currentProject && (
                        <Box>
                          <FormLabel mt={3}>Company Name:</FormLabel>
                          <Text>{currentProject.companyName}</Text>
                          <FormLabel mt={3}>Company Address:</FormLabel>
                          <Text>{currentProject.companyAddress}</Text>
                          <FormLabel mt={3}>Phone Number:</FormLabel>
                          <Text>{currentProject.phoneNumber}</Text>
                          <FormLabel mt={3}>Email Address:</FormLabel>
                          <Text>{currentProject.email}</Text>
                          <FormLabel mt={3}>Website:</FormLabel>
                          <Text>{currentProject.website}</Text>
                          <FormLabel mt={3}>City:</FormLabel>
                          <Text>{currentProject.city}</Text>
                          <FormLabel mt={3}>State</FormLabel>
                          <Text>{currentProject.state}</Text>
                          <FormLabel mt={3}>Country:</FormLabel>
                          <Text>{currentProject.country}</Text>
                          <FormLabel mt={3}>Zipcode:</FormLabel>
                          <Text>{currentProject.zipcode}</Text>
                          <FormLabel mt={3}>Nature of Business:</FormLabel>
                          <Text>{currentProject.natureOfBusiness}</Text>
                          <FormLabel mt={3}>Number of Employees:</FormLabel>
                          <Text>{currentProject.NoOfEmployees}</Text>
                          <FormLabel mt={3}>
                            Company Registration Type:
                          </FormLabel>
                          <Text>{currentProject.registrationType}</Text>
                          <FormLabel mt={3}>Standard:</FormLabel>
                          <Text>{currentProject.standard}</Text>
                          <FormLabel mt={3}>Service Type:</FormLabel>
                          <Text>{currentProject.serviceType}</Text>
                        </Box>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default UserProject;
