import {
  Flex,
  Center,
  Text,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useNavigate } from "react-router";

const ProjectCard = ({ project, index }) => {
  const [status, setStatus] = useState("Pending");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleProjectCardClick = () => {
    navigate(`${project?._id}`);
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setIsOpen(false);
  };
  return (
    <Flex
      w="full"
      boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
      px="3"
      py="2"
      bg="white"
      color="#000"
      borderRadius="md"
      justifyContent="space-between"
      fontWeight="medium"
    >
      <Center w="20%" onClick={handleProjectCardClick} cursor="pointer">
        <Text>{index}</Text>
      </Center>
      <Center w="20%" onClick={handleProjectCardClick} cursor="pointer">
        <Text>{project?._id}</Text>
      </Center>
      <Center w="20%" onClick={handleProjectCardClick} cursor="pointer">
        <Text> Project Name</Text>
      </Center>
      <Center w="20%">
        <Text>Assignee</Text>
      </Center>
      <Center w="20%">
        <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <MenuButton
            as={Button}
            onClick={() => setIsOpen((prev) => !prev)}
            rightIcon={<Icon as={isOpen ? FiChevronUp : FiChevronDown} />}
            bg="gray.100"
            _hover={{ bg: "gray.200" }}
            p={2}
            color={
              status === "Pending"
                ? "orange.500"
                : status === "In Progress"
                ? "blue.500"
                : "green.500"
            }
          >
            {status}
          </MenuButton>
          <MenuList>
            {["Pending", "In Progress", "Completed"].map((option) => (
              <MenuItem key={option} onClick={() => handleStatusChange(option)}>
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Center>
    </Flex>
  );
};

export default ProjectCard;
