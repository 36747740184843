import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "../Components/Navbar/Navbar";
import { useLocation } from "react-router";

const routerTitles = {
  home: "Dashboard",
  addnewlead: "Add New Lead",
  fresh: "Fresh Lead",
  enquiries: "Enquiries",
  proposal_shared: "Proposal Shared",
  active: "Active",
  prospective: "Prospective",
  pipeline: "Pipeline",
  dump: "Dump",
  projects: "Projects",
  customers: "Customers",
  finance: "Finance",
  income: "Income",
  statistics: "Statistics",
  approvals: "Approvals",
  report: "Report",
  users: "Users",
  chat: "Chat",
};

const AuthenticatedLayout = ({ children }) => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const currentRoute = pathSegments[pathSegments.length - 1];
  const currentTitle = routerTitles[currentRoute] || "";
  return (
    <Flex direction="column" h="100vh" mx="auto">
      <Navbar title={currentTitle} />
      <Box flex="1" overflowY="auto">
        {children}
      </Box>
    </Flex>
  );
};

export default AuthenticatedLayout;
