import { Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router";
import Login from "./Components/Login/Login";
import DashBoardLayout from "./layouts/DashBoardLayout";
import ProposalShared from "./Components/ProposalShared";
import Active from "./Components/Active";
import Prospective from "./Components/Prospective";
import Pipeline from "./Components/Pipeline";
import Dump from "./Components/Dump";
import Income from "./Components/Income";
import Statistics from "./Components/Statistics";
import Approvals from "./Components/Approvals";
import CirculerShimmer from "./utils/CirculerShimmer";
import ProjectPage from "./Components/Projects/ProjectPage";
import ProjectCardPage from "./Components/Projects/ProjectCardPage";
import UserDashboard from "./Components/Users/UserDashboard";
import UserFresh from "./Components/Users/UserFresh";
import UserProposals from "./Components/Users/UserProposals";
import UserActive from "./Components/Users/UserActive";
import UserProspective from "./Components/Users/UserProspective";
import UserPipeline from "./Components/Users/UserPipeline";
import UserDump from "./Components/Users/UserDump";
import UserProject from "./Components/Users/UserProject";
import UserCustomers from "./Components/Users/UserCustomer";
import UserIncome from "./Components/Users/UserIncome";
import UserStatistics from "./Components/Users/UserStatistics";
import UserApprovals from "./Components/Users/UserApprovals";
import ManagerDump from "./Components/Managers/ManagerDumps";
import ManagerFresh from "./Components/Managers/ManagerFreshs";
import ManagerIncome from "./Components/Managers/ManagerIncome";
import ManagerPipeline from "./Components/Managers/ManagerPipelines";
import ManagerProposalShared from "./Components/Managers/ManagerProposalShareds";
import ManagerProspective from "./Components/Managers/ManagerProspectives";
import ManagerStatistics from "./Components/Managers/ManagerStatistics";
import ManagerUsers from "./Components/Managers/ManagerUser";
import ManagerActive from "./Components/Managers/ManagerActives";
import ManagerApprovals from "./Components/Managers/ManagerApprovals";
import ManagerChat from "./Components/Managers/ManagerChat";
import ManagerCustomers from "./Components/Managers/ManagerCustomer";
import ManagerDashboard from "./Components/Managers/Landings/ManagerDashboards";

// module wise lazy loading...
const Home = lazy(() => import("./Components/Home/Home"));
const AddNewLead = lazy(() => import("./Components/AddNewLead"));
const Fresh = lazy(() => import("./Components/Fresh"));
const Enquiries = lazy(() => import("./Components/Enquiries"));
const Projects = lazy(() => import("./Components/Projects/Projects"));
const Customers = lazy(() => import("./Components/Customers"));
const Finance = lazy(() => import("./Components/Finance"));
const Reports = lazy(() => import("./Components/Report/Reports"));
const Users = lazy(() => import("./Components/Users"));
const Chat = lazy(() => import("./Components/Chat/Chat"));

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="dashboard" element={<DashBoardLayout />}>
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="addnewlead"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <AddNewLead />
              </Suspense>
            }
          />
          <Route
            path="fresh"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Fresh />
              </Suspense>
            }
          />
          <Route
            path="enquiries"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Enquiries />
              </Suspense>
            }
          >
            <Route index element={<ProposalShared />} />
            <Route path="proposal_shared" element={<ProposalShared />} />
            <Route path="active" element={<Active />} />
            <Route path="prospective" element={<Prospective />} />
            <Route path="pipeline" element={<Pipeline />} />
            <Route path="dump" element={<Dump />} />
          </Route>
          <Route
            path="projects"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Projects />
              </Suspense>
            }
          >
            <Route path="" element={<ProjectCardPage />} />
            <Route path=":project_id" element={<ProjectPage />} />
          </Route>
          <Route
            path="customers"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Customers />
              </Suspense>
            }
          />
          <Route
            path="finance"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Finance />
              </Suspense>
            }
          >
            <Route index element={<Income />} />
            <Route path="income" element={<Income />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="approvals" element={<Approvals />} />
          </Route>
          <Route
            path="report"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Reports />
              </Suspense>
            }
          />
          <Route
            path="users"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Users />
              </Suspense>
            }
          />
          <Route path="userDashboard" element={<UserDashboard />} />
          <Route path="userFresh" element={<UserFresh />} />
          <Route path="userProposals" element={<UserProposals />} />
          <Route path="userActive" element={<UserActive />} />
          <Route path="userProspective" element={<UserProspective />} />
          <Route path="userPipeline" element={<UserPipeline />} />
          <Route path="userDump" element={<UserDump />} />
          <Route path="userProject" element={<UserProject />} />
          <Route path="userCustomers" element={<UserCustomers />} />
          <Route path="userIncome" element={<UserIncome />} />
          <Route path="userStatistics" element={<UserStatistics />} />
          <Route path="userApprovals" element={<UserApprovals />} />
          <Route path="managerDump" element={<ManagerDump />} />
          <Route path="managerFresh" element={<ManagerFresh />} />
          <Route path="managerIncome" element={<ManagerIncome />} />
          <Route path="managerDump" element={<ManagerDump />} />
          <Route path="managerPipeline" element={<ManagerPipeline />} />
          <Route
            path="managerProposalShared"
            element={<ManagerProposalShared />}
          />
          <Route path="managerProspective" element={<ManagerProspective />} />
          <Route path="managerStatistics" element={<ManagerStatistics />} />
          <Route path="managerUser" element={<ManagerUsers />} />
          <Route path="managerActive" element={<ManagerActive />} />
          <Route path="managerApprovals" element={<ManagerApprovals />} />
          <Route path="managerChat" element={<ManagerChat />} />
          <Route path="managerCustomers" element={<ManagerCustomers />} />
          <Route path="managerDashboard" element={<ManagerDashboard />} />
          <Route
            path="chat"
            element={
              <Suspense fallback={<CirculerShimmer />}>
                <Chat />
              </Suspense>
            }
          />
        </Route>
      </Routes>
      {/* <AllRoutes/> */}
    </div>
  );
};

export default App;
