export const GET_TODAYS_LEAD_COUNT_REQUEST = "GET_TODAYS_LEAD_COUNT_REQUEST";
export const GET_TODAYS_LEAD_COUNT_SUCCESS = "GET_TODAYS_LEAD_COUNT_SUCCESS";
export const GET_TODAYS_LEAD_COUNT_FAILURE = "GET_TODAYS_LEAD_COUNT_FAILURE";

export const GET_YEARLY_LEADS_COUNT_REQUEST = "GET_YEARLY_LEADS_COUNT_REQUEST";
export const GET_YEARLY_LEADS_COUNT_SUCCESS = "GET_YEARLY_LEADS_COUNT_SUCCESS";
export const GET_YEARLY_LEADS_COUNT_FAILURE = "GET_YEARLY_LEADS_COUNT_FAILURE";

export const GET_LEADS_COUNT_IN_MONTH_REQUEST = "GET_LEADS_COUNT_IN_MONTH_REQUEST";
export const GET_LEADS_COUNT_IN_MONTH_SUCCESS = "GET_LEADS_COUNT_IN_MONTH_SUCCESS";
export const GET_LEADS_COUNT_IN_MONTH_FAILURE = "GET_LEADS_COUNT_IN_MONTH_FAILURE";

export const ADDNEWLEADS_REQUEST = "ADDNEWLEADS_REQUEST";
export const ADDNEWLEADS_SUCCESS = "ADDNEWLEADS_SUCCESS";
export const ADDNEWLEADS_FAILURE = "ADDNEWLEADS_FAILURE";

export const GETALLLEADS_REQUEST = "GETALLLEADS_REQUEST";
export const GETALLLEADS_SUCCESS = "GETALLLEADS_SUCCESS";
export const GETALLLEADS_FAILURE = "GETALLLEADS_FAILURE";

export const LEADSTATUS_REQUEST = "LEADSTATUS_REQUEST";
export const LEADSTATUS_SUCCESS = "LEADSTATUS_SUCCESS";
export const LEADSTATUS_FAILURE = "LEADSTATUS_FAILURE";

export const ASSIGNEELEAD_REQUEST = "ASSIGNEELEAD_REQUEST";
export const ASSIGNEELEAD_SUCCESS = "ASSIGNEELEAD_SUCCESS";
export const ASSIGNEELEAD_FAILURE = "ASSIGNEELEAD_FAILURE";

export const GETPROPOSAL_REQUEST = "GETPROPOSAL_REQUEST";
export const GETPROPOSAL_SUCCESS = "GETPROPOSAL_SUCCESS";
export const GETPROPOSAL_FAILURE = "GETPROPOSAL_FAILURE";

export const GETACTIVE_REQUEST = "GETACTIVE_REQUEST";
export const GETACTIVE_SUCCESS = "GETACTIVE_SUCCESS";
export const GETACTIVE_FAILURE = "GETACTIVE_FAILURE";

export const GETPROSPECTIVE_REQUEST = "GETPROSPECTIVE_REQUEST";
export const GETPROSPECTIVE_SUCCESS = "GETPROSPECTIVE_SUCCESS";
export const GETPROSPECTIVE_FAILURE = "GETPROSPECTIVE_FAILURE";

export const GETPIPELINE_REQUEST = "GETPIPELINE_REQUEST";
export const GETPIPELINE_SUCCESS = "GETPIPELINE_SUCCESS";
export const GETPIPELINE_FAILURE = "GETPIPELINE_FAILURE";

export const GETDUMP_REQUEST = "GETDUMP_REQUEST";
export const GETDUMP_SUCCESS = "GETDUMP_SUCCESS";
export const GETDUMP_FAILURE = "GETDUMP_FAILURE";

export const GETPROJECT_REQUEST = "GETPROJECT_REQUEST";
export const GETPROJECT_SUCCESS = "GETPROJECT_SUCCESS";
export const GETPROJECT_FAILURE = "GETPROJECT_FAILURE";

export const GETCUSTOMERS_REQUEST = "GETCUSTOMERS_REQUEST";
export const GETCUSTOMERS_SUCCESS = "GETCUSTOMERS_SUCCESS";
export const GETCUSTOMERS_FAILURE = "GETCUSTOMERS_FAILURE";

export const GETREVIEW_REQUEST = "GETREVIEW_REQUEST";
export const GETREVIEW_SUCCESS = "GETREVIEW_SUCCESS";
export const GETREVIEW_FAILURE = "GETREVIEW_FAILURE";

export const EDITLEADS_REQUEST = "EDITLEADS_REQUEST";
export const EDITLEADS_SUCCESS = "EDITLEADS_SUCCESS";
export const EDITLEADS_FAILURE = "EDITLEADS_FAILURE";

export const SAVEDESCRIPTION_REQUEST = "SAVEDESCRIPTION_REQUEST";
export const SAVEDESCRIPTION_SUCCESS = "SAVEDESCRIPTION_SUCCESS";
export const SAVEDESCRIPTION_FAILURE = "SAVEDESCRIPTION_FAILURE";

export const EDITDESCRIPTION_REQUEST = "EDITDESCRIPTION_REQUEST";
export const EDITDESCRIPTION_SUCCESS = "EDITDESCRIPTION_SUCCESS";
export const EDITDESCRIPTION_FAILURE = "EDITDESCRIPTION_FAILURE";

export const SAVECOMMENT_REQUEST = "SAVECOMMENT_REQUEST";
export const SAVECOMMENT_SUCCESS = "SAVECOMMENT_SUCCESS";
export const SAVECOMMENT_FAILURE = "SAVECOMMENT_FAILURE";

export const EDITCOMMENT_REQUEST = "EDITCOMMENT_REQUEST";
export const EDITCOMMENT_SUCCESS = "EDITCOMMENT_SUCCESS";
export const EDITCOMMENT_FAILURE = "EDITCOMMENT_FAILURE";

export const DELETECOMMENT_REQUEST = "DELETECOMMENT_REQUEST";
export const DELETECOMMENT_SUCCESS = "DELETECOMMENT_SUCCESS";
export const DELETECOMMENT_FAILURE = "DELETECOMMENT_FAILURE";

export const GETUSER_REQUEST = "GETUSER_REQUEST";
export const GETUSER_SUCCESS = "GETUSER_SUCCESS";
export const GETUSER_FAILURE = "GETUSER_FAILURE";

export const GETUSERS_REQUEST = "GETUSERS_REQUEST";
export const GETUSERS_SUCCESS = "GETUSERS_SUCCESS";
export const GETUSERS_FAILURE = "GETUSERS_FAILURE";

export const UPDATEPROFILE_REQUEST = "UPDATEPROFILE_REQUEST";
export const UPDATEPROFILE_SUCCESS = "UPDATEPROFILE_SUCCESS";
export const UPDATEPROFILE_FAILURE = "UPDATEPROFILE_FAILURE";

export const CREATEUSER_REQUEST = "CREATEUSER_REQUEST";
export const CREATEUSER_SUCCESS = "CREATEUSER_SUCCESS";
export const CREATEUSER_FAILURE = "CREATEUSER_FAILURE";

export const DELETEUSER_REQUEST = "DELETEUSER_REQUEST";
export const DELETEUSER_SUCCESS = "DELETEUSER_SUCCESS";
export const DELETEUSER_FAILURE = "DELETEUSER_FAILURE";

export const GETALLPROPOSAL_REQUEST = "GETALLPROPOSAL_REQUEST";
export const GETALLPROPOSAL_SUCCESS = "GETALLPROPOSAL_SUCCESS";
export const GETALLPROPOSAL_FAILURE = "GETALLPROPOSAL_FAILURE";

export const GETLEADS_REQUEST = "GETLEADS_REQUEST";
export const GETLEADS_SUCCESS = "GETLEADS_SUCCESS";
export const GETLEADS_FAILURE = "GETLEADS_FAILURE";

export const GETALLPIPELINE_REQUEST = "GETALLPIPELINE_REQUEST";
export const GETALLPIPELINE_SUCCESS = "GETALLPIPELINE_SUCCESS";
export const GETALLPIPELINE_FAILURE = "GETALLPIPELINE_FAILURE";

export const GETADMINDUMP_REQUEST = "GETADMINDUMP_REQUEST";
export const GETADMINDUMP_SUCCESS = "GETADMINDUMP_SUCCESS";
export const GETADMINDUMP_FAILURE = "GETADMINDUMP_FAILURE";

export const GETADMINPROSPECTIVE_REQUEST = "GETADMINPROSPECTIVE_REQUEST";
export const GETADMINPROSPECTIVE_SUCCESS = "GETADMINPROSPECTIVE_SUCCESS";
export const GETADMINPROSPECTIVE_FAILURE = "GETADMINPROSPECTIVE_FAILURE";
