import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  useDisclosure,
  useToast,
  Grid,
} from "@chakra-ui/react";
import { FiSave, FiTrash2, FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Createuser, UpdateProfile, deleteUser, getUsers } from "../../Redux/AppReducer/Action";

const ManagerUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getAllUsers = useSelector((state) => state.app.GETUSERSs);
  const token = useSelector((state) => state.auth.token);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userData, setUserData] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
    phoneNumber: "",
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editData, setEditData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5); // Increased rows per page

  useEffect(() => {
    dispatch(getUsers(token));
  }, [dispatch, token]);

  useEffect(() => {
    setUserData(getAllUsers?.users || []);
  }, [getAllUsers]);

  const filteredUsers = useMemo(() => {
    if (!searchTerm) return userData;
    return userData.filter((user) =>
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, userData]);

  // Handle Input Change (for Add New & Edit)
  const handleInputChange = (e, isEdit = false) => {
    const { name, value } = e.target;
    if (isEdit) {
      setEditData({ ...editData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle Add New User
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password, role, phoneNumber } = formData;
    if (!firstName || !lastName || !email || !password || !role || !phoneNumber) {
      toast({
        title: "Validation Error",
        description: "All fields are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    dispatch(Createuser(token, formData))
      .then(() => {
        dispatch(getUsers(token));
        toast({ title: "User Created Successfully", status: "success", duration: 3000, isClosable: true });
        onClose();
        setFormData({ firstName: "", lastName: "", email: "", password: "", role: "", phoneNumber: "" });
      })
      .catch((error) => {
        toast({ title: "Failed to Create User", description: error.message || "Something went wrong.", status: "error", duration: 3000, isClosable: true });
      });
  };

  // Handle Edit User
  const handleEdit = (user) => {
    setEditingRowId(user.id);
    setEditData(user);
  };

  // Handle Save Edited User
  const handleSave = () => {
    dispatch(UpdateProfile(token, editingRowId, editData))
      .then(() => {
        dispatch(getUsers(token));
        toast({ title: "User Updated Successfully", status: "success", duration: 3000, isClosable: true });
        setEditingRowId(null);
      })
      .catch((error) => {
        toast({ title: "Failed to Update User", description: error.message || "Something went wrong.", status: "error", duration: 3000, isClosable: true });
      });
  };

  // Handle Delete User
  const handleDeleteUser = (id) => {
    dispatch(deleteUser(id)).then(() => {
      dispatch(getUsers(token));
      toast({ title: "User Deleted Successfully", status: "success", duration: 3000, isClosable: true });
    });
  };

  return (
    <Box boxShadow="0 0 10px rgba(128, 128, 128, 0.5)" borderRadius={7}>
      <Flex p={3} mb={4} justifyContent="space-between">
        <Heading as="h5" fontSize={"20px"} fontWeight={"bold"}>Users</Heading>
        <Flex>
          <Input w={"50%"} height={8} ml={2} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search users by name" />
          <Button colorScheme="red" ml={4} onClick={onOpen}>Add New</Button>
        </Flex>
      </Flex>

      <TableContainer>
        <Table mt={2}>
          <Thead>
            <Tr backgroundColor="#4b4b4b">
              {["SL No.", "First Name", "Last Name", "Email", "Role", "Phone Number", "Action"].map((header, index) => (
                <Th key={index} color="white">{header}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.map((user, index) => {
              const isEditing = editingRowId === user.id;
              return (
                <Tr key={user.id}>
                  <Td>{index + 1}</Td>
                  {["firstName", "lastName", "email", "role", "phoneNumber"].map((field) => (
                    <Td key={field}>
                      {isEditing ? <Input name={field} value={editData[field]} onChange={(e) => handleInputChange(e, true)} /> : user[field]}
                    </Td>
                  ))}
                  <Td>
                    {isEditing ? (
                      <IconButton icon={<FiSave />} onClick={handleSave} mr={2} />
                    ) : (
                      <IconButton icon={<FiEdit2 />} onClick={() => handleEdit(user)} />
                    )}
                    <IconButton icon={<FiTrash2 />} colorScheme="red" ml={2} onClick={() => handleDeleteUser(user.id)} />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Add User Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              {["firstName", "lastName", "email", "password", "role", "phoneNumber"].map((field) => (
                <FormControl key={field} mb={4}>
                  <FormLabel>{field.replace(/([A-Z])/g, " $1").trim()}</FormLabel>
                  <Input name={field} value={formData[field]} onChange={(e) => handleInputChange(e)} placeholder={`Enter ${field}`} />
                </FormControl>
              ))}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleFormSubmit}>Submit</Button>
            <Button onClick={onClose} ml={3}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ManagerUsers;
