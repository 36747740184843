import {
  Box,
  Checkbox,
  Flex,
  Grid,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { TfiPackage } from "react-icons/tfi";
import { FaRegClock } from "react-icons/fa6";
import { CiCreditCard1 } from "react-icons/ci";
import { TbCurrencyDollar } from "react-icons/tb";
import { FiAlertOctagon } from "react-icons/fi";
import { TbXboxX } from "react-icons/tb";
import { LuEye } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router";

const Income = ({ isSidebarExpanded }) => {
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/addincome");
  };

  return (
    <Box
      boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
      // p={3}
      borderRadius={7}
    >
      <Flex p={6} mb={4}>
        <Heading
          color={"#151617"}
          as="h5"
          fontSize={"18px"}
          fontWeight={"bold"}
          fontFamily={"Public Sans, sans-serif"}
        >
          31 Income(s) Found | Total Tax : 190| Total Tds : 0 | Approved Amount
          : 7426265
        </Heading>
        <Box>
          <Flex
            fontSize={"14px"}
            border={"1px solid red"}
            lineHeight={7}
            borderRadius={8}
            fontFamily={"Public Sans, sans-serif"}
            color={"#D80000"}
          >
            <Text
              borderRight={"1px solid #D80000"}
              pl={4}
              pr={4}
              cursor={"pointer"}
              _hover={{ bg: "#D8000026" }}
            >
              Export
            </Text>
            <Text
              borderRight={"1px solid #D80000"}
              pl={4}
              pr={4}
              cursor={"pointer"}
              _hover={{ bg: "#D8000026" }}
            >
              Filter
            </Text>
            <Text
              borderRight={"1px solid #D80000"}
              pl={4}
              pr={4}
              cursor={"pointer"}
              _hover={{ bg: "#D8000026" }}
            >
              View Graph
            </Text>
            <Text
              pl={4}
              pr={4}
              cursor={"pointer"}
              _hover={{ bg: "#D8000026" }}
              onClick={handleOnClick}
            >
              Add New
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Box
        boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
        // p={3}
        mt={8}
        borderRadius={7}
        position={"relative"}
        top={100}
      >
        <Grid templateColumns="repeat(3, 1fr)" gap={3}>
          <Box
            height={"180px"}
            width={"180px"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"35px"}
                width={"35px"}
                color="#7367f0 !important"
                backgroundColor={"#eae8fd !important"}
                pl={"7px"}
                pt={"7px"}
              >
                <TfiPackage size={20} />
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                Overall
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              7,426,265.0
            </Heading>
          </Box>
          <Box
            height={"180px"}
            width={"180px"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"35px"}
                width={"35px"}
                color="#ff9f43 !important"
                backgroundColor={"#fff1e3 !important"}
                pl={"7px"}
                pt={"7px"}
              >
                <FaRegClock size={20} />
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                This Month
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
          <Box
            height={"180px"}
            width={"180px"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"35px"}
                width={"35px"}
                color="#28c76f !important"
                backgroundColor={"#dff7e9 !important"}
                pl={"7px"}
                pt={"7px"}
              >
                <CiCreditCard1 size={20} />
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                This Week
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
          <Box
            height={"180px"}
            width={"180px"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"35px"}
                width={"35px"}
                color="#00cfe8 !important"
                backgroundColor={"#d9f8fc !important"}
                pl={"7px"}
                pt={"7px"}
              >
                <TbCurrencyDollar size={20} />
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                Today
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
          <Box
            height={"180px"}
            width={"180px"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"35px"}
                width={"35px"}
                color="#a8aaae !important"
                backgroundColor={"#f2f2f3 !important"}
                pl={"7px"}
                pt={"7px"}
              >
                <FiAlertOctagon size={20} />
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                Pending
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
          <Box
            height={"180px"}
            width={"180px"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"35px"}
                width={"35px"}
                color="#ea5455 !important"
                backgroundColor={"#fce5e6 !important"}
                pl={"7px"}
                pt={"7px"}
              >
                <TbXboxX size={20} />
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                Rejected
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
        </Grid>
        <Box p={10}>
          <TableContainer height={"70vh"}>
            <Table>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th>
                    <Checkbox />
                  </Th>
                  <Th color={"white"}>#</Th>
                  <Th color={"white"}>Creator</Th>
                  <Th color={"white"}>ID</Th>
                  <Th color={"white"}>Date</Th>
                  <Th color={"white"}>User</Th>
                  <Th color={"white"}>Branch</Th>
                  <Th color={"white"}>Category</Th>
                  <Th color={"white"}>Amount</Th>
                  <Th color={"white"}>Details</Th>
                  <Th color={"white"}>Tax</Th>
                  <Th color={"white"}>TDS</Th>
                  <Th color={"white"}>Receipt</Th>
                  <Th color={"white"}>Mode</Th>
                  <Th color={"white"}>Product/Service</Th>
                  <Th color={"white"}>Client</Th>
                  <Th color={"white"}>Approval Status</Th>
                  <Th color={"white"}>Followup Date</Th>
                  <Th color={"white"}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Checkbox />
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    1
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    Meghana S WING 1
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}></Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    Mar 30, 2024
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    Meghana S WING 1
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}></Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    General
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    167149.00
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    ISO 22716
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}></Td>
                  <Td fontSize={"13px"} color={"#151617"}></Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    ---
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    Others
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}></Td>
                  <Td fontSize={"13px"} color={"#151617"}></Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    Approved
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}></Td>
                  <Td>
                    <HStack>
                      <LuEye />
                      <BsThreeDotsVertical />
                    </HStack>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Income;
