import * as types from "./ActionTypes";
import axios from "axios";
// import { Reducer } from "../AuthReducer/Reducer";

//home
export const GetTodaysLeadsCountByStatus = (token) => async (dispatch) => {
  dispatch({ type: types.GET_TODAYS_LEAD_COUNT_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getTodaysLeadsCountByStatus`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response?.data;
    dispatch({
      type: types.GET_TODAYS_LEAD_COUNT_SUCCESS,
      payload: data,
    });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GET_TODAYS_LEAD_COUNT_FAILURE });
  }
};

export const getMonthlyLeadsCountForYear = (token) => async (dispatch) => {
  dispatch({ type: types.GET_YEARLY_LEADS_COUNT_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/MonthlyLeadsCountforyear`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response?.data;
    dispatch({
      type: types.GET_YEARLY_LEADS_COUNT_SUCCESS,
      payload: data,
    });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GET_YEARLY_LEADS_COUNT_FAILURE });
  }
};

export const getLeadsCountForCurrentMonth = (token) => async (dispatch) => {
  dispatch({ type: types.GET_LEADS_COUNT_IN_MONTH_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/LeadsCountForCurrentMonth`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response?.data;
    dispatch({
      type: types.GET_LEADS_COUNT_IN_MONTH_SUCCESS,
      payload: data,
    });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GET_LEADS_COUNT_IN_MONTH_FAILURE });
  }
};

//AddNewLeads
export const AddNewLeads = (token, formData) => async (dispatch) => {
  dispatch({ type: types.ADDNEWLEADS_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/addnewlead`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.ADDNEWLEADS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.ADDNEWLEADS_FAILURE });
  }
};

//Get All New Lead
export const GetAllLeads = (token) => async (dispatch) => {
  dispatch({ type: types.GETALLLEADS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getAllLeads`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETALLLEADS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETALLLEADS_FAILURE });
  }
};

//Lead Status
export const LeadStatus = (token, LeadID, action) => async (dispatch) => {
  dispatch({ type: types.LEADSTATUS_REQUEST });
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/lead/${LeadID}`,
      { status: action },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.LEADSTATUS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.LEADSTATUS_FAILURE });
  }
};

//Assignee Lead (Admin)
export const AssigneeLead = (leadId, userId, token) => async (dispatch) => {
  dispatch({ type: types.ASSIGNEELEAD_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/assign-lead`,
      { leadId, userId },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.ASSIGNEELEAD_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.ASSIGNEELEAD_FAILURE });
  }
};

//Get Proposal Shared
export const GetProposal = (token, salesRepId) => async (dispatch) => {
  dispatch({ type: types.GETPROPOSAL_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getProposalShared/${salesRepId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Ensuring token format is correct
        },
      }
    );

    const { leads } = response.data; // Extracting leads array
    dispatch({ type: types.GETPROPOSAL_SUCCESS, payload: leads });

    console.log("Fetched leads:", leads);
  } catch (error) {
    console.error(
      "Error fetching proposals:",
      error.response?.data?.message || error.message
    );

    dispatch({
      type: types.GETPROPOSAL_FAILURE,
      payload: error.response?.data?.message || "Failed to fetch proposals",
    });
  }
};

//Get Active Leads
export const GetActive = (token, salesRepId) => async (dispatch) => {
  dispatch({ type: types.GETACTIVE_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getactiveleads/${salesRepId}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const { leads } = response.data;
    dispatch({ type: types.GETACTIVE_SUCCESS, payload: leads });
    console.log("Fetched leads:", leads);
  } catch (error) {
    console.error(
      "Error fetching active:",
      error.response?.data?.message || error.message
    );
    dispatch({
      type: types.GETACTIVE_FAILURE,
      payload: error.response?.data?.message || "Failed to fetch active",
    });
  }
};

//Get Prospective
export const GetProspective = (token, salesRepId) => async (dispatch) => {
  dispatch({ type: types.GETPROSPECTIVE_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getProspective/${salesRepId}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const { leads } = response.data;
    dispatch({ type: types.GETPROSPECTIVE_SUCCESS, payload: leads });
    console.log("Fetched leads:", leads);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETPROSPECTIVE_FAILURE });
  }
};

//Get All Pipeline
export const GetPipeline = (token, salesRepId) => async (dispatch) => {
  dispatch({ type: types.GETPIPELINE_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getPipeline/${salesRepId}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const { leads } = response.data;
    dispatch({ type: types.GETPIPELINE_SUCCESS, payload: leads });
    console.log("Fetched leads:", leads);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETPIPELINE_FAILURE });
  }
};

//Get All Dump
export const GetDump = (token, salesRepId) => async (dispatch) => {
  dispatch({ type: types.GETDUMP_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getDump/${salesRepId}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const { leads } = response.data;
    dispatch({ type: types.GETDUMP_SUCCESS, payload: leads });
    console.log("Fetched leads:", leads);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETDUMP_FAILURE });
  }
};

//Get All Project
export const GetProject = (token, salesRepId) => async (dispatch) => {
  dispatch({ type: types.GETPROJECT_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getprojects/${salesRepId}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETPROJECT_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETPROJECT_FAILURE });
  }
};

//Get Customers

export const GetCustomers = (token) => async (dispatch) => {
  dispatch({ type: types.GETCUSTOMERS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getCustomers`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETCUSTOMERS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETCUSTOMERS_FAILURE });
  }
};

//Get review(Approval finance)

export const GetReview = (token) => async (dispatch) => {
  dispatch({ type: types.GETREVIEW_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getreview`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETREVIEW_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETREVIEW_FAILURE });
  }
};

//Edit Fresh Page Lead
export const EditLeads =
  (token, LeadID, updatedLeadData) => async (dispatch) => {
    dispatch({ type: types.EDITLEADS_REQUEST });
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/lead/${LeadID}/edit`,
        updatedLeadData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.EDITLEADS_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.EDITLEADS_FAILURE });
    }
  };

// Add Description on Project Page(Project Details)
export const SaveDescription =
  (token, leadId, description) => async (dispatch) => {
    dispatch({ type: types.SAVEDESCRIPTION_REQUEST });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/addDescription`,
        { leadId, description },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const data = response.data;
      dispatch({ type: types.SAVEDESCRIPTION_SUCCESS, payload: data });
      console.log("Description saved:", data);
    } catch (error) {
      console.log("Error saving description:", error);
      dispatch({ type: types.SAVEDESCRIPTION_FAILURE, payload: error.message });
    }
  };

//Update Description on project page
export const EditDescription =
  (token, leadId, description) => async (dispatch) => {
    dispatch({ type: types.EDITDESCRIPTION_REQUEST });
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/updateDescription`,
        { leadId, description },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.EDITDESCRIPTION_SUCCESS, payload: data });
      console.log("description updated:", data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.EDITDESCRIPTION_FAILURE });
    }
  };

// Add Comment on the project Page
export const SaveComment =
  (token, leadId, userId, commentText) => async (dispatch) => {
    dispatch({ type: types.SAVECOMMENT_REQUEST });
    console.log("Saving comment for leadId:", leadId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/addComment`,
        { leadId, userId, commentText },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const data = response.data;
      dispatch({ type: types.SAVECOMMENT_SUCCESS, payload: data });
      console.log("Comment saved:", data);
    } catch (error) {
      console.log("Error saving comment:", error);
      dispatch({ type: types.SAVECOMMENT_FAILURE, payload: error.message });
    }
  };

//Edit Comment on the project Details
export const EditComment =
  (token, leadId, commentId, newCommentText) => async (dispatch) => {
    dispatch({ type: types.EDITCOMMENT_REQUEST });
    console.log("commentId:", commentId);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/editComment`,
        { leadId, commentId, newCommentText },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({
        type: types.EDITCOMMENT_SUCCESS,
        payload: { ...data, commentId },
      });
      console.log({ ...data, commentId });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.EDITCOMMENT_FAILURE });
    }
  };

//delete comment of the project details
export const deleteComment = (token, leadId, commentId) => async (dispatch) => {
  dispatch({ type: types.DELETECOMMENT_REQUEST });

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/deleteComment`,
      {
        headers: {
          Authorization: `${token}`,
        },
        data: {
          leadId,
          commentId,
        },
      }
    );

    const data = response.data;
    dispatch({ type: types.DELETECOMMENT_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.DELETECOMMENT_FAILURE });
  }
};

export const getUsers = (token) => async (dispatch) => {
  dispatch({ type: types.GETUSERS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getAllUsers`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responseData = response.data;
    dispatch({ type: types.GETUSERS_SUCCESS, payload: responseData });
    console.log(responseData);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETUSERS_FAILURE });
  }
};

export const getUser = (token, id) => async (dispatch) => {
  console.log(id);
  dispatch({ type: types.GETUSER_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responseData = response.data;
    dispatch({ type: types.GETUSER_SUCCESS, payload: responseData });
    console.log(responseData);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETUSER_FAILURE });
  }
};

// update user
export const UpdateProfile = (token, id, profileData) => async (dispatch) => {
  dispatch({ type: types.UPDATEPROFILE_REQUEST });
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/user/${id}/profile`,
      profileData,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const data = response.data;
    dispatch({ type: types.UPDATEPROFILE_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.error(error);
    dispatch({
      type: types.UPDATEPROFILE_FAILURE,
      payload: error.response?.data?.message || "Profile update failed",
    });
  }
};

//Create User

export const Createuser = (token, formData) => async (dispatch) => {
  dispatch({ type: types.CREATEUSER_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/createUser`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.CREATEUSER_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.CREATEUSER_FAILURE });
  }
};

//delete user by id

export const deleteUser = (id) => async (dispatch) => {
  dispatch({ type: types.DELETEUSER_FAILURE });

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/deleteUser/${id}`,
      {
        data: {
          id,
        },
      }
    );

    const data = response.data;
    dispatch({ type: types.DELETEUSER_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.DELETEUSER_FAILURE });
  }
};

//Get allProposalShared (Admin)
export const GetAllProposal = (token) => async (dispatch) => {
  dispatch({ type: types.GETALLPROPOSAL_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/adminProposalShared`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETALLPROPOSAL_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETALLPROPOSAL_FAILURE });
  }
};

//get AllActive Leads(Admin)
export const GetLeads = (token) => async (dispatch) => {
  dispatch({ type: types.GETLEADS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/adminactiveLeads`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETLEADS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETLEADS_FAILURE });
  }
};

//Get AllPipeline(Admin)
export const GetAllPipeline = (token) => async (dispatch) => {
  dispatch({ type: types.GETALLPIPELINE_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/adminPipeline`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETALLPIPELINE_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETALLPIPELINE_FAILURE });
  }
};


//get admin dump
export const GetAdminDump = (token) => async (dispatch) => {
  dispatch({ type: types.GETADMINDUMP_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/adminDump`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const { leads } = response.data;
    dispatch({ type: types.GETADMINDUMP_SUCCESS, payload: leads });
    console.log("Fetched leads:", leads);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETADMINDUMP_FAILURE });
  }
};



//get admin prospectives

export const GetAdminProspective = (token) => async (dispatch) => {
  dispatch({ type: types.GETADMINPROSPECTIVE_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/adminProspective`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const { leads } = response.data;
    dispatch({ type: types.GETADMINPROSPECTIVE_SUCCESS, payload: leads });
    console.log("Fetched leads:", leads);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETADMINPROSPECTIVE_FAILURE });
  }
};


export {};
