import { Box, Spinner } from "@chakra-ui/react";

const CirculerShimmer = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      w="full"
      h="full"
    >
      <Spinner color="red.600" size="xl" speed="0.65s" />
    </Box>
  );
};

export default CirculerShimmer;
