import React from "react";
import { Box, Flex, Heading, Text, useColorMode } from "@chakra-ui/react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const ManagerGraph = () => {
  const currentMonth = new Date().getMonth();
  const leadCounts = [12, 19, 3, 5, 2, 3, 9, 10, 15, 13, 7, 8];
  const dailyData = Array.from({ length: 30 }, () => Math.floor(Math.random() * 10) + 1); // Example daily data for the current month
  const { colorMode } = useColorMode();

  const barData = {
    labels: [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ],
    datasets: [
      {
        label: "Leads",
        data: leadCounts,
        backgroundColor: leadCounts.map((_, index) =>
          index === currentMonth ? '#3182CE' : '#A0AEC0' // Highlight current month
        ),
      },
    ],
  };

  const barOptions = {
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true, grid: { display: false } },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Leads: ${context.raw}`;
          },
        },
      },
    },
  };

  const lineData = {
    labels: Array.from({ length: 30 }, (_, i) => i + 1), // Days of the month
    datasets: [
      {
        label: "Daily Data",
        data: dailyData,
        fill: true,
        borderColor: "#3182CE",
        backgroundColor: "rgba(49, 130, 206, 0.2)",
        tension: 0.4, // This gives the line a smooth wave-like flow
      },
    ],
  };

  const lineOptions = {
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true, grid: { display: false } },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Value: ${context.raw}`;
          },
        },
      },
    },
  };

  return (
    <Box mt={"20px"} p={5} boxShadow="md" borderRadius="md" bg="white">
      <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-evenly">
        
        {/* Left Side: Full Width Bar Graph */}
        <Box p={3} w={"50%"}>
          <Heading
            mb={4}
            fontSize={22}
            textAlign={"left"}
            color={colorMode === "dark" ? "white" : "#000000"}
          >
           Leads Overview 
          </Heading>
          <Box p={5} borderWidth="1px" borderRadius="10px">
            <Bar data={barData} options={barOptions} width={400} height={200} />
          </Box>
        </Box>

        {/* Right Side: Wave Flow Line Graph */}
        <Box p={3} w={"50%"}>
          <Heading
            mb={4}
            fontSize={22}
            textAlign={"left"}
            color={colorMode === "dark" ? "white" : "#000000"}
          >
            Daily Leads - Current Month
          </Heading>
          <Box p={5} borderWidth="1px" borderRadius="10px" mt={"55px"}>
            <Line data={lineData} options={lineOptions} width={400} height={200} />
          </Box>
        </Box>

      </Flex>
    </Box>
  );
};

export default ManagerGraph;
