import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "../Components/Sidebar/Sidebar";
import AuthenticatedLayout from "./AuthenticatedLayout";
import { Outlet } from "react-router";

const DashBoardLayout = () => {
  return (
    <AuthenticatedLayout>
      <Flex h="full">
        <Sidebar />
        <Box
          flex="1"
          overflowY="auto"
          px={3}
          py={4}
          bg="white"
          borderTopWidth="2px"
          borderTopStyle="solid"
          borderTopColor="gray.600"
        >
          <Outlet />
        </Box>
      </Flex>
    </AuthenticatedLayout>
  );
};

export default DashBoardLayout;
