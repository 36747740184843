import {
    Box,
    Checkbox,
    Flex,
    Grid,
    Heading,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
  } from "@chakra-ui/react";
  import React from "react";
  import { TfiPackage } from "react-icons/tfi";
  import { FaRegClock } from "react-icons/fa6";
  import { CiCreditCard1 } from "react-icons/ci";
  import { TbCurrencyDollar } from "react-icons/tb";
  import { LuEye } from "react-icons/lu";
  import { BsThreeDotsVertical } from "react-icons/bs";
  import { useNavigate } from "react-router";
  
  const UserStatistics = ({ isSidebarExpanded }) => {
    const navigate = useNavigate();
  
    const handleOnClick = (e) => {
      e.preventDefault();
      navigate("/addexpense");
    };
  
    return (
      <Box
        boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
        // p={3}
        borderRadius={7}
      >
        <Grid templateColumns="repeat(4, 1fr)" columnGap={5}>
          <Box
            height={"auto"}
            width={"auto"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"45px"}
                width={"45px"}
                color="#7367f0 !important"
                backgroundColor={"#eae8fd !important"}
                borderRadius={"5px"}
                pl={"7px"}
                pt={"7px"}
              >
                <Box m={"3px"}>
                  <TfiPackage size={25} />
                </Box>
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                Overall
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
          <Box
            height={"auto"}
            width={"auto"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"45px"}
                width={"45px"}
                color="#ff9f43 !important"
                backgroundColor={"#fff1e3 !important"}
                borderRadius={"5px"}
                pl={"7px"}
                pt={"7px"}
              >
                <Box m={"3px"}>
                  <FaRegClock size={25} />
                </Box>
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                This Month
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
          <Box
            height={"auto"}
            width={"auto"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"45px"}
                width={"45px"}
                color="#28c76f !important"
                backgroundColor={"#dff7e9 !important"}
                borderRadius={"5px"}
                pl={"7px"}
                pt={"7px"}
              >
                <Box m={"3px"}>
                  <CiCreditCard1 size={25} />
                </Box>
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                This Week
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
          <Box
            height={"auto"}
            width={"auto"}
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            p={"28px"}
          >
            <HStack>
              <Box
                height={"45px"}
                width={"45px"}
                color="#00cfe8 !important"
                backgroundColor={"#d9f8fc !important"}
                borderRadius={"5px"}
                pl={"7px"}
                pt={"7px"}
              >
                <Box m={"3px"}>
                  <TbCurrencyDollar size={25} />
                </Box>
              </Box>
              <Heading
                fontSize={"18px"}
                color={"#5d596c"}
                fontFamily={"Public Sans"}
              >
                Today
              </Heading>
            </HStack>
            <Heading
              fontSize={"22px"}
              color={"#5d596c"}
              fontFamily={"Public Sans"}
              mt={5}
              textAlign={"left"}
            >
              0.0
            </Heading>
          </Box>
        </Grid>
        <Box p={10}>
          <TableContainer height={"auto"}>
            <Table>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th>
                    <Checkbox />
                  </Th>
                  <Th color={"white"}>SL NO</Th>
                  <Th color={"white"}>Agent</Th>
                  <Th color={"white"}>Product/Service</Th>
                  <Th color={"white"}>Approval Status</Th>
                  <Th color={"white"}>Followup Date</Th>
                  <Th color={"white"}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Checkbox />
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    1
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    Meghana S WING 1
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    gjg
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    Approved
                  </Td>
                  <Td fontSize={"13px"} color={"#151617"}>
                    kh
                  </Td>
                  <Td>
                    <HStack>
                      <LuEye />
                      <BsThreeDotsVertical />
                    </HStack>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  };
  
  export default UserStatistics;
  