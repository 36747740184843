import {
  Box,
  Flex,
  Text,
  Heading,
  HStack,
  Button,
  Input,
} from "@chakra-ui/react";
import { useParams } from "react-router";

const ProjectPage = () => {
  const { project_id } = useParams();
  return (
    <Flex direction="column" gap="3">
      <Heading as="h2" fontSize="22" fontWeight="bold" color="grey">
        Project Details
      </Heading>
      <Box fontSize="16px" fontWeight="medium">
        Project id: {project_id}
      </Box>
      <HStack>
        <Text>Project Name:</Text>
        <Input w="50"/>
      </HStack>
    </Flex>
  );
};

export default ProjectPage;
