import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import { Navigate, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { GetReview, LeadStatus } from "../Redux/AppReducer/Action";

const Approvals = ({ isSidebarExpanded }) => {
  const navigate = useNavigate();
  const [leadActions, setLeadActions] = useState({});
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getReview = useSelector((state) => state.app.GETREVIEWs);
  console.log(getReview);


  useEffect(() =>{
    dispatch(GetReview(token))
  }, [dispatch, token])

  const handleActionChange = async (leadID, action) => {
    await dispatch(LeadStatus(token, leadID, action));

    setLeadActions((prevActions) => ({
      ...prevActions,
      [leadID]: action,
    }));
  };

  return (
    <Box
      boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
      // p={3}
      borderRadius={7}
    >
      <Flex p={3} mb={4}>
        <Heading
          color={"#151617"}
          as="h5"
          fontSize={"18px"}
          fontWeight={"bold"}
          fontFamily={"Public Sans, sans-serif"}
        >
          {getReview?.leads?.length} record
          {getReview?.leads?.length !== 1 ? "s" : ""} found
        </Heading>
      </Flex>
      <TableContainer>
        <Table>
          <Thead>
            <Tr backgroundColor={"#4b4b4b"}>
              <Th>
                <Checkbox />
              </Th>
              <Th color={"white"}>SL No.</Th>
              <Th color={"white"}>ID</Th>
              <Th color={"white"}>Agent</Th>
              <Th color={"white"}>Company Name</Th>
              <Th color={"white"}>Standard</Th>
              <Th color={"white"}>Service Type</Th>
              <Th color={"white"}>Closure Value</Th>
              <Th color={"white"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getReview?.leads?.map((Leads, index) => (
              <Tr key={index.id}>
                <Td>
                  <Checkbox />
                </Td>
                <Td>{index + 1}</Td>
                <Td>{Leads._id.slice(0, 6)}</Td>
                <Td>{Leads.name}</Td>
                <Td>{Leads.companyWebsite}</Td>
                <Td>{Leads.standard}</Td>
                <Td>{Leads.services}</Td>
                <Td>{Leads.closureValue}</Td>
                <Td>
                  <Select
                    w={"150px"}
                    placeholder="Select option"
                    value={leadActions.status}
                    onChange={(e) =>
                      handleActionChange(Leads._id, e.target.value)
                    }
                  >
                    <option value="Move to projects">Move to projects</option>
                    <option value="Move to customers">Move to customers</option>
                  </Select>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Approvals;
