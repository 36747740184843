import { applyMiddleware, combineReducers } from 'redux';
import { legacy_createStore } from 'redux';
// import { composeWithDevTools } from '@redux-devtools/extension';
import {thunk} from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from './enc'; // Assuming you have defined your encryption function in 'enc.js'
import { Reducer as AppReducer } from './AppReducer/Reducer';
import { Reducer as AuthReducer } from './AuthReducer/Reducer';

// Redux Persist configuration
const persistConfig = {
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_STATE_ENC, // Use your secret key from environment variables
      onError: function (error) {
        console.log(error); // Handle errors during encryption
      },
    }),
  ],
  key: 'persist-key', // Key for storing the persisted state in localStorage/sessionStorage
  storage, // Storage engine to use (localStorage/sessionStorage)
  stateReconciler: (inboundState, originalState) => {
    // Custom state reconciler function to merge incoming state with existing state
    if (originalState.app) {
      return {
        ...inboundState,
        app: {
          ...inboundState.app,
          popup: originalState.app.popup, // Preserve 'popup' property in 'app' reducer
       
        },
      };
    }
    if (originalState.auth) {
      return {
        ...inboundState,
        auth: {
          ...inboundState.auth,
        },
      };
    }
    return inboundState; // Return inboundState as-is if no specific handling is required
  },
};

// Combine your reducers into a single root reducer
const RootReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
});

// Create a persisted reducer with Redux Persist
const persistedReducer = persistReducer(persistConfig, RootReducer);

// Create the Redux store with persistedReducer and middleware
const store = legacy_createStore(
  persistedReducer,
  applyMiddleware(thunk)
);

// Persistor for persisting state
const persistedStore = persistStore(store);

// Export the store and persistedStore for use in your application
export { store, persistedStore };
