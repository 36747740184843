import * as types from "./ActionTypes";
const initialState = {
  ADDNEWLEADSs: [],
  GETTODAYSLEADCOUNTBYSTATUs: [],
  GETYEARLYLEADSCOUNTs: [],
  GETLEADSCOUNTINMONTHs: [],
  GETALLLEADSs: [],
  LEADSTATUSs: [],
  ASSIGNEELEADs: [],
  GETPROPOSALs: [],
  GETACTIVEs: [],
  GETPROSPECTIVEs: [],
  GETPIPELINEs: [],
  GETDUMPs: [],
  GETPROJECTs: [],
  GETCUSTOMERSs: [],
  GETREVIEWs: [],
  EDITLEADSs: [],
  SAVEDESCRIPTIONs: {},
  EDITDESCRIPTIONs: [],
  SAVECOMMENTs: {},
  EDITCOMMENTs: [],
  DELETECOMMENTs: [],
  GETUSERs: [],
  GETUSERSs: [],
  UPDATEPROFILEs: [],
  CREATEUSERs: [],
  DELETEUSERs: [],
  GETALLPROPOSALs:[],
  GETLEADSs:[],
  GETALLPIPELINEs:[],
  GETADMINDUMPs:[],
  GETADMINPROSPECTIVEs:[],
  isLoading: false,
  isError: false,
  user: null,
};

//Dashboard Details
export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Get Today's Lead Count by Status
    case types.GET_TODAYS_LEAD_COUNT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GET_TODAYS_LEAD_COUNT_SUCCESS: {
      return {
        ...state,
        GETTODAYSLEADCOUNTBYSTATUs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GET_TODAYS_LEAD_COUNT_FAILURE: {
      return {
        ...state,
        GETTODAYSLEADCOUNTBYSTATUs: {},
        isLoading: false,
        isError: true,
      };
    }

    //get yearly leads count monthly wise
    case types.GET_YEARLY_LEADS_COUNT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GET_YEARLY_LEADS_COUNT_SUCCESS: {
      return {
        ...state,
        GETYEARLYLEADSCOUNTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GET_YEARLY_LEADS_COUNT_FAILURE: {
      return {
        ...state,
        GETYEARLYLEADSCOUNTs: {},
        isLoading: false,
        isError: true,
      };
    }

    //get Leads Count For Current Month
    case types.GET_LEADS_COUNT_IN_MONTH_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GET_LEADS_COUNT_IN_MONTH_SUCCESS: {
      return {
        ...state,
        GETLEADSCOUNTINMONTHs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GET_LEADS_COUNT_IN_MONTH_FAILURE: {
      return {
        ...state,
        GETLEADSCOUNTINMONTHs: {},
        isLoading: false,
        isError: true,
      };
    }

    //add new leads
    case types.ADDNEWLEADS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.ADDNEWLEADS_SUCCESS: {
      return {
        ...state,
        ADDNEWLEADSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.ADDNEWLEADS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ADDNEWLEADSs: [],
        isError: true,
      };
    }

    //Get All New
    case types.GETALLLEADS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETALLLEADS_SUCCESS: {
      return {
        ...state,
        GETALLLEADSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETALLLEADS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETALLLEADSs: [],
        isError: true,
      };
    }

    //Lead Status
    case types.LEADSTATUS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.LEADSTATUS_SUCCESS: {
      return {
        ...state,
        LEADSTATUSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.LEADSTATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        LEADSTATUSs: [],
        isError: true,
      };
    }

    //Assigneed Lead (Admin)
    case types.ASSIGNEELEAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.ASSIGNEELEAD_SUCCESS: {
      return {
        ...state,
        ASSIGNEELEADs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.ASSIGNEELEAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ASSIGNEELEADs: [],
        isError: true,
      };
    }

    //Get Proposal Shared
    case types.GETPROPOSAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPROPOSAL_SUCCESS: {
      return {
        ...state,
        GETPROPOSALs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPROPOSAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPROPOSALs: [],
        isError: true,
      };
    }

    //Get Active Leads
    case types.GETACTIVE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETACTIVE_SUCCESS: {
      return {
        ...state,
        GETACTIVEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETACTIVE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETACTIVEs: [],
        isError: true,
      };
    }

    //Get Prospective
    case types.GETPROSPECTIVE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPROSPECTIVE_SUCCESS: {
      return {
        ...state,
        GETPROSPECTIVEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPROSPECTIVE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPROSPECTIVEs: [],
        isError: true,
      };
    }

    //Get Pipeline
    case types.GETPIPELINE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPIPELINE_SUCCESS: {
      return {
        ...state,
        GETPIPELINEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPIPELINE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPIPELINEs: [],
        isError: true,
      };
    }

    //Get all Dump
    case types.GETDUMP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETDUMP_SUCCESS: {
      return {
        ...state,
        GETDUMPs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETDUMP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETDUMPs: [],
        isError: true,
      };
    }

    //Get All Project
    case types.GETPROJECT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPROJECT_SUCCESS: {
      return {
        ...state,
        GETPROJECTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPROJECT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPROJECTs: [],
        isError: true,
      };
    }

    //Get All Customers
    case types.GETCUSTOMERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETCUSTOMERS_SUCCESS: {
      return {
        ...state,
        GETCUSTOMERSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETCUSTOMERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETCUSTOMERSs: [],
        isError: true,
      };
    }

    //Get All Review(finance)
    case types.GETREVIEW_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETREVIEW_SUCCESS: {
      return {
        ...state,
        GETREVIEWs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETREVIEW_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETREVIEWs: [],
        isError: true,
      };
    }

    //Edit Fresh page Lead
    case types.EDITLEADS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.EDITLEADS_SUCCESS: {
      return {
        ...state,
        EDITLEADSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.EDITLEADS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        EDITLEADSs: [],
        isError: true,
      };
    }

    // Add description on project page(project details)
    case types.SAVEDESCRIPTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.SAVEDESCRIPTION_SUCCESS: {
      return {
        ...state,
        SAVEDESCRIPTIONs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.SAVEDESCRIPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        SAVEDESCRIPTIONs: {},
        isError: true,
      };
    }

    //update description on the project page
    case types.EDITDESCRIPTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.EDITDESCRIPTION_SUCCESS: {
      return {
        ...state,
        EDITDESCRIPTIONs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.EDITDESCRIPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        EDITDESCRIPTIONs: [],
        isError: true,
      };
    }

    //Add Comment on the project Page
    case types.SAVECOMMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.SAVECOMMENT_SUCCESS: {
      return {
        ...state,
        SAVECOMMENTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.SAVECOMMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        SAVECOMMENTs: {},
        isError: true,
      };
    }

    //Edit Comment of the project details
    case types.EDITCOMMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.EDITCOMMENT_SUCCESS: {
      return {
        ...state,
        EDITCOMMENTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.EDITCOMMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        EDITCOMMENTs: [],
        isError: true,
      };
    }

    //Delete the comment of project details
    case types.DELETECOMMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.DELETECOMMENT_SUCCESS: {
      return {
        ...state,
        DELETECOMMENTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.DELETECOMMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        DELETECOMMENTs: {},
        isError: true,
      };
    }

    case types.GETUSER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETUSER_SUCCESS: {
      return {
        ...state,
        user: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETUSER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETUSERs: {},
        isError: true,
      };
    }

    case types.GETUSERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETUSERS_SUCCESS: {
      return {
        ...state,
        GETUSERSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETUSERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETUSERSs: {},
        isError: true,
      };
    }

    case types.UPDATEPROFILE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.UPDATEPROFILE_SUCCESS: {
      return {
        ...state,
        UPDATEPROFILEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.UPDATEPROFILE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        UPDATEPROFILEs: [],
        isError: true,
      };
    }

    case types.CREATEUSER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.CREATEUSER_SUCCESS: {
      return {
        ...state,
        CREATEUSERs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.CREATEUSER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        CREATEUSERs: [],
        isError: true,
      };
    }

    case types.DELETEUSER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.DELETEUSER_SUCCESS: {
      return {
        ...state,
        DELETEUSERs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.DELETEUSER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        DELETEUSERs: {},
        isError: true,
      };
    }

    //Get AllProposal (Admin)
    case types.GETALLPROPOSAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETALLPROPOSAL_SUCCESS: {
      return {
        ...state,
        GETALLPROPOSALs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETALLPROPOSAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETALLPROPOSALs: {}, 
        isError: true,
      }
    }

    //Get AllActive Leads(Admin)
    case types.GETLEADS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETLEADS_SUCCESS: {
      return {
        ...state,
        GETLEADSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETLEADS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETLEADSs: {}, 
        isError: true,
      }
    }

    //get allPipeline(Admin)
    case types.GETALLPIPELINE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETALLPIPELINE_SUCCESS: {
      return {
        ...state,
        GETALLPIPELINEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETALLPIPELINE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETALLPIPELINEs: {}, 
        isError: true,
      }
    }

      //Get admin dump

      case types.GETADMINDUMP_REQUEST: {
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      }
      case types.GETADMINDUMP_SUCCESS: {
        return {
          ...state,
          GETADMINDUMPs: payload,
          isLoading: false,
          isError: false,
        };
      }
      case types.GETADMINDUMP_FAILURE: {
        return {
          ...state,
          isLoading: false,
          GETADMINDUMPs: [], 
          isError: true,
  
        }
      }
  
     
  
      //get admin prospective
  
      case types.GETADMINPROSPECTIVE_REQUEST: {
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      }
      case types.GETADMINPROSPECTIVE_SUCCESS: {
        return {
          ...state,
          GETADMINPROSPECTIVEs: payload,
          isLoading: false,
          isError: false,
        };
      }
      case types.GETADMINPROSPECTIVE_FAILURE: {
        return {
          ...state,
          isLoading: false,
          GETADMINPROSPECTIVEs: [],
          isError: true,
  
        }
      }



    default:
      return state;
  }
};
