import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  EditLeads,
  GetAllProposal,
  LeadStatus,
} from "../Redux/AppReducer/Action";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";

const ProposalShared = () => {
  const [leadActions, setLeadActions] = useState({});
  const [selectedLead, setSelectedLead] = useState(null);
  const [editableLead, setEditableLead] = useState(null);
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getAllproposals = useSelector((state) => state.app.GETALLPROPOSALs);

  useEffect(() => {
    dispatch(GetAllProposal(token));
  }, [dispatch, token]);
  const handleActionChange = async (leadID, action) => {
    await dispatch(LeadStatus(token, leadID, action));
    dispatch(GetAllProposal(token));
    setLeadActions((prevActions) => ({
      ...prevActions,
      [leadID]: action,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableLead((prevLead) => ({
      ...prevLead,
      [name]: value,
    }));
  };

  const handleLeadClick = (lead, isEditing = false) => {
    setSelectedLead(lead);
    if (isEditing) {
      setEditableLead(lead); // Set lead for editing
      onEditOpen();
    } else {
      onViewOpen();
    }
  };

  const handleSave = async () => {
    if (editableLead && editableLead._id) {
      await dispatch(EditLeads(token, editableLead._id, editableLead));
      dispatch(GetAllProposal(token));
      console.log("Save lead: ", editableLead);
      onEditClose();
    } else {
      console.log("No lead selected for editing.");
    }
  };

  const assignee = ["Unassigned", "Name 1", "Name 2", "Name 3", "Name 4"];

  const filteredAssignee = assignee.filter((assignee) =>
    assignee.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleAssigneeSelect = (assignee) => {
    setSelectedAssignee(assignee);
    setSearchInput("");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Box
      boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
      borderRadius={7}
      w="full"
    >
      <Flex p={3} mb={4}>
        <Heading
          color={"#151617"}
          as="h5"
          fontSize={"18px"}
          fontWeight={"bold"}
          fontFamily={"Public Sans, sans-serif"}
        >
          {getAllproposals?.leads?.length} record
          {getAllproposals?.leads?.length !== 1 ? "s" : ""} found
        </Heading>
      </Flex>
      <TableContainer>
        <Table>
          <Thead>
            <Tr backgroundColor="#4b4b4b">
              {[
                "",
                "SL No.",
                "",
                "ID",
                "Name",
                "Email Address",
                "Phone Number",
                "Action",
              ].map((text, i) => (
                <Th key={i} color="white">
                  {i === 0 ? <Checkbox /> : text}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {getAllproposals?.leads?.map((lead, index) => (
              <Tr key={lead.id}>
                {[
                  <Checkbox />,
                  index + 1,
                  <>
                    <EditIcon
                      cursor="pointer"
                      mr={2}
                      onClick={() => handleLeadClick(lead, true)}
                    />
                    <ViewIcon
                      cursor="pointer"
                      onClick={() => handleLeadClick(lead)}
                    />
                  </>,
                  lead._id.slice(-6),
                  lead.name,
                  lead.emailAddress,
                  lead.phoneNumber,
                  <Select
                    w="150px"
                    placeholder="Select option"
                    value={leadActions.status}
                    onChange={(e) =>
                      handleActionChange(lead._id, e.target.value)
                    }
                  >
                    <option value="Submit for review">Submit for review</option>
                  </Select>,
                ].map((content, i) => (
                  <Td key={i}>{content}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Drawer
        isOpen={isViewOpen}
        placement="right"
        onClose={onViewClose}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="red">View Details</DrawerHeader>
          <DrawerBody>
            {selectedLead && (
              <Box
                p={4}
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                mb={4}
              >
                <Box ml={10}>
                  {[
                    ["ID", selectedLead._id],
                    ["Date", formatDate(selectedLead.date)],
                    ["Name", selectedLead.name],
                    ["Email Address", selectedLead.emailAddress],
                    ["Phone Number", selectedLead.phoneNumber],
                    ["Alternate Contact", selectedLead.alternateContact],
                    ["Executive", selectedLead.executive],
                    ["Deal Stage", selectedLead.dealStage],
                    ["WhatsApp Number", selectedLead.whatsAppNumber],
                    ["Company Name", selectedLead.companyName],
                    ["Company Address", selectedLead.companyAddress],
                    ["City", selectedLead.city],
                    ["State", selectedLead.state],
                    [
                      "Expected Closure Date",
                      formatDate(selectedLead.expectedClosureDate),
                    ],
                    ["Country", selectedLead.country],
                    ["Website", selectedLead.website],
                    ["Number of Employees", selectedLead.numberOfEmployees],
                    ["Nature of Business", selectedLead.natureOfBusiness],
                    [
                      "Company Registration Type",
                      selectedLead.companyRegistrationType,
                    ],
                    ["Standard", selectedLead.standard],
                    ["Service Type", selectedLead.serviceType],
                    ["Closure Probability", selectedLead.closureProbability],
                    ["Closure Value", selectedLead.closureValue],
                    ["Gross Value", selectedLead.grossValue],
                    ["Action", selectedLead.action],
                  ].map(([label, value], i) => (
                    <Text key={i} mb={2}>
                      <Text as="span" fontWeight="bold">
                        {label}:
                      </Text>
                      <Text as="span" ml={2}>
                        {value}
                      </Text>
                    </Text>
                  ))}
                </Box>
              </Box>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={onViewClose}>Close</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isEditOpen}
        placement="right"
        onClose={onEditClose}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={"red"}>Details</DrawerHeader>

          <DrawerBody>
            {editableLead && (
              <Tabs variant="soft-rounded" colorScheme="red">
                <TabList>
                  <Tab>Edit Details</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <SimpleGrid columns={2} spacing={4}>
                      {[
                        { label: "ID", key: "_id" },
                        { label: "Date", key: "date", format: true },
                        { label: "Name", key: "name" },
                        { label: "Email Address", key: "emailAddress" },
                        { label: "Phone Number", key: "phoneNumber" },
                        { label: "Alternate Contact", key: "alternateContact" },
                        { label: "Executive", key: "executive" },
                        { label: "Deal Stage", key: "dealStage" },
                        { label: "WhatsApp Number", key: "whatsAppNumber" },
                        { label: "Company Name", key: "companyName" },
                        { label: "Company Address", key: "companyAddress" },
                        { label: "City", key: "city" },
                        { label: "State", key: "state" },
                        {
                          label: "Expected Closure Date",
                          key: "expectedClosureDate",
                          format: true,
                        },
                        { label: "Country", key: "country" },
                        { label: "Website", key: "website" },
                        {
                          label: "Total Number Of Employees",
                          key: "totalNumberOfEmployees",
                        },
                        {
                          label: "Nature of Business",
                          key: "natureOfBusiness",
                        },
                        {
                          label: "Company Registration Type",
                          key: "companyRegistrationType",
                        },
                        { label: "Standard", key: "standard" },
                        { label: "Service Type", key: "serviceType" },
                        {
                          label: "Closure Probability",
                          key: "closureProbability",
                        },
                        { label: "Closure Value", key: "closureValue" },
                        { label: "Gross Value", key: "grossValue" },
                        { label: "Action", key: "action" },
                      ].map(({ label, key, format }) => (
                        <Box key={key}>
                          <Text fontWeight="bold">{label}:</Text>
                          <Input
                            value={
                              format
                                ? formatDate(editableLead[key])
                                : editableLead[key]
                            }
                            name={key}
                            onChange={handleInputChange}
                          />
                        </Box>
                      ))}
                    </SimpleGrid>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={onEditClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ProposalShared;
