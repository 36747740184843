import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Input,
  SimpleGrid,
  Grid,
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  AssigneeLead,
  EditLeads,
  GetAllLeads,
  getUsers,
  LeadStatus,
} from "../../Redux/AppReducer/Action";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import { IoChevronDownOutline } from "react-icons/io5";

const ManagerFresh = ({ isSidebarExpanded }) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const getallleads = useSelector((state) => state.app.GETALLLEADSs);
  // console.log(getallleads)
  const GetUsers = useSelector((state) => state.app.GETUSERSs);
  // console.log(GetUsers)
  const dispatch = useDispatch();
  const [leadActions, setLeadActions] = useState({});
  const [selectedLead, setSelectedLead] = useState(null);
  const [editableLead, setEditableLead] = useState(null);
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(GetAllLeads(token));
    dispatch(getUsers(token));
  }, [dispatch, token]);
  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/addnewfresh");
  };

  const handleActionChange = async (LeadID, action) => {
    await dispatch(LeadStatus(token, LeadID, action));
    dispatch(GetAllLeads(token));
    setLeadActions((prevActions) => ({
      ...prevActions,
      [LeadID]: action,
    }));
  };

  const handleLeadClick = (lead, isEditing = false) => {
    setSelectedLead(lead);
    if (isEditing) {
      setEditableLead(lead);
      onEditOpen();
    } else {
      onViewOpen();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableLead((prevLead) => ({
      ...prevLead,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (editableLead && editableLead._id) {
      await dispatch(EditLeads(token, editableLead._id, editableLead));
      dispatch(GetAllLeads(token));
      setSelectedAssignee(assignee);
      console.log("Save lead: ", editableLead);
      onEditClose();
    } else {
      console.log("No lead selected for editing.");
    }
  };

  const assignee = [
    "Unassigned",
    ...GetUsers?.users?.map((user) => user.firstName),
  ];

  const filteredAssignee = assignee.filter((assignee) =>
    assignee.toLowerCase().includes(searchInput.toLowerCase())
  );
  const handleAssigneeSelect = (assignee) => {
    if (selectedLead) {
      const leadId = selectedLead._id;
      const userId = GetUsers?.users?.find(
        (user) => user.firstName === assignee
      )?.id;

      if (userId) {
        dispatch(AssigneeLead(leadId, userId));
        setSelectedAssignee(assignee);
        dispatch(GetAllLeads(token));
        setSearchInput("");
      } else {
        console.error("User not found");
      }
    } else {
      console.error("No lead selected");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Box w="full">
      <Box boxShadow="0 0 10px rgba(128, 128, 128, 0.5)" borderRadius={7}>
        <Flex p={3} mb={4}>
          <Heading
            color={"#151617"}
            as="h5"
            fontSize={"18px"}
            fontWeight={"bold"}
            fontFamily={"Public Sans, sans-serif"}
          >
            {getallleads?.leads?.length} record
            {getallleads?.leads?.length !== 1 ? "s" : ""} found
          </Heading>
          <Box position={"absolute"} right={10}>
            <Flex
              fontSize={"14px"}
              border={"1px solid red"}
              lineHeight={7}
              borderRadius={8}
              fontFamily={"Public Sans, sans-serif"}
              color={"#D80000"}
            >
              <Text
                borderRight={"1px solid #D80000"}
                pl={4}
                pr={4}
                cursor={"pointer"}
                _hover={{ bg: "#D8000026" }}
              >
                X
              </Text>
              <Text
                borderRight={"1px solid #D80000"}
                pl={4}
                pr={4}
                cursor={"pointer"}
                _hover={{ bg: "#D8000026" }}
              >
                Filter
              </Text>
              <Text
                borderRight={"1px solid #D80000"}
                pl={4}
                pr={4}
                cursor={"pointer"}
                _hover={{ bg: "#D8000026" }}
              >
                Bulk Upload
              </Text>
              <Text
                pl={4}
                pr={4}
                cursor={"pointer"}
                _hover={{ bg: "#D8000026" }}
                onClick={handleOnClick}
              >
                Add New
              </Text>
            </Flex>
          </Box>
        </Flex>
        <TableContainer>
          <Table>
            <Thead>
              <Tr backgroundColor={"#4b4b4b"}>
                <Th>
                  <Checkbox />
                </Th>
                <Th color={"white"}>SL No.</Th>
                <Th></Th>
                <Th color={"white"}>ID</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Email Address</Th>
                <Th color={"white"}>Phone Number</Th>
                <Th color={"white"}>Follow Up Date</Th>
                <Th color={"white"}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getallleads?.leads?.map((Leads, index) => (
                <Tr key={formatDate(Leads.id)}>
                  <Td>
                    <Checkbox />
                  </Td>

                  <Td>{index + 1}</Td>
                  <Td>
                    <EditIcon
                      cursor="pointer"
                      mr={2}
                      onClick={() => handleLeadClick(Leads, true)}
                    />
                    <ViewIcon
                      cursor="pointer"
                      onClick={() => handleLeadClick(Leads)}
                    />
                  </Td>
                  <Td>{Leads._id.slice(-6)}</Td>
                  <Td>{Leads.name}</Td>
                  <Td>{Leads.emailAddress}</Td>
                  <Td>{Leads.phoneNumber}</Td>
                  <Td>{formatDate(Leads.followUpDate)}</Td>
                  <Td>
                    <Select
                      w={"150px"}
                      placeholder="Select option"
                      value={leadActions[Leads._id] || ""}
                      onChange={(e) =>
                        handleActionChange(Leads._id, e.target.value)
                      }
                    >
                      <option value="Proposal Shared">Proposal Shared</option>
                      <option value="Active">Active</option>
                      <option value="Prospective">Prospective</option>
                      <option value="Pipeline">Pipeline</option>
                      <option value="Dump">Dump</option>
                    </Select>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Drawer
        isOpen={isViewOpen}
        placement="right"
        onClose={onViewClose}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={"red"}>View Details</DrawerHeader>

          <DrawerBody>
            {selectedLead && (
              <Box
                p={4}
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                mb={4}
              >
                <Box ml={10}>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      ID:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead._id}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Name:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.name}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Email Address:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.emailAddress}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Phone Number:{" "}
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.phoneNumber}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Executive:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.executive}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Alternate Contact:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.alternateContact}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Designation:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.designation}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Address:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.address}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Country:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.country}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      State:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.state}
                    </Text>
                  </Text>

                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Status:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.status}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Standard:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.standard}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Created At:
                    </Text>
                    <Text as="span" ml={2}>
                      {formatDate(selectedLead.createdAt)}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Total Number Of Employees:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.totalnumberOfEmployees}
                    </Text>
                  </Text>
                </Box>
              </Box>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={onViewClose}>Close</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isEditOpen}
        placement="right"
        onClose={onEditClose}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={"red"}>Details</DrawerHeader>

          <DrawerBody>
            {editableLead && (
              <Tabs variant="soft-rounded" colorScheme="red">
                <TabList>
                  <Tab>Edit Details</Tab>
                  <Tab>Assignee</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <SimpleGrid columns={2} spacing={4}>
                      <Box>
                        <Text fontWeight="bold">ID:</Text>
                        <Input
                          value={editableLead._id}
                          name="_id"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Name:</Text>
                        <Input
                          value={editableLead.name}
                          name="name"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Email Address:</Text>
                        <Input
                          value={editableLead.emailAddress}
                          name="emailAddress"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Phone Number:</Text>
                        <Input
                          value={editableLead.phoneNumber}
                          name="phoneNumber"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Follow Up Date:</Text>
                        <Input
                          value={formatDate(editableLead.followUpDate)}
                          name="followUpDate"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Executive:</Text>
                        <Input
                          value={editableLead.executive}
                          name="executive"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Alternate Contact:</Text>
                        <Input
                          value={editableLead.alternateContact}
                          name="alternateContact"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Designation:</Text>
                        <Input
                          value={editableLead.designation}
                          name="designation"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Address:</Text>
                        <Input
                          value={editableLead.address}
                          name="address"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Country:</Text>
                        <Input
                          value={editableLead.country}
                          name="country"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">State:</Text>
                        <Input
                          value={editableLead.state}
                          name="state"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Status:</Text>
                        <Input
                          value={editableLead.status}
                          name="status"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Standard:</Text>
                        <Input
                          value={editableLead.standard}
                          name="standard"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Created At:</Text>
                        <Input
                          value={editableLead.createdAt}
                          name="createdAt"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">
                          Total Number Of Employees:
                        </Text>
                        <Input
                          value={editableLead.totalnumberOfEmployees}
                          name="totalnumberOfEmployees"
                          onChange={handleInputChange}
                        />
                      </Box>
                    </SimpleGrid>
                  </TabPanel>

                  <TabPanel>
                    <Menu>
                      <MenuButton
                        width="250px"
                        as={Button}
                        rightIcon={<IoChevronDownOutline />}
                        textAlign="left"
                        color={"#6f6b7d"}
                        background={"none"}
                        border={"1px solid #dbdade"}
                        _hover={{ bg: "none" }}
                      >
                        {selectedAssignee ? selectedAssignee : "Unassigned"}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="auto">
                        <Box p={2}>
                          <Input
                            placeholder="Select..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </Box>
                        {filteredAssignee.length > 0 ? (
                          filteredAssignee.map((assignee, index) => (
                            <MenuItem
                              _hover={{
                                bg: "#D8000026",
                                textColor: "#D80000",
                              }}
                              key={index}
                              onClick={() => handleAssigneeSelect(assignee)}
                            >
                              {assignee}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No matches found</MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={onEditClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ManagerFresh;
