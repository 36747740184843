import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  SimpleGrid,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import Navbar from "./Navbar/Navbar";
import { Navigate, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { EditLeads, GetProposal, LeadStatus } from "../../Redux/AppReducer/Action";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import { IoChevronDownOutline } from "react-icons/io5";

const UserProposals = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const [leadActions, setLeadActions] = useState({});
  const [selectedLead, setSelectedLead] = useState(null);
  const [editableLead, setEditableLead] = useState(null);
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getAllproposal = useSelector((state) => state.app.GETPROPOSALs);
  console.log(getAllproposal);

  // useEffect(() => {
  //   const refreshLeads = () => {
  //     dispatch(GetProposal(token));
  //   };
  //   // refreshLeads();
  //   const intervalId = setInterval(() => {
  //     // refreshLeads();
  //   });

  //   return () => clearInterval(intervalId);
  // }, [dispatch, token]);

  useEffect(() => {
    dispatch(GetProposal(token));
  }, [dispatch, token]);

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/addnewproposal");
  };

  const handleActionChange = async (leadID, action) => {
    await dispatch(LeadStatus(token, leadID, action));

    setLeadActions((prevActions) => ({
      ...prevActions,
      [leadID]: action,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableLead((prevLead) => ({
      ...prevLead,
      [name]: value,
    }));
  };

  const handleLeadClick = (lead, isEditing = false) => {
    setSelectedLead(lead);
    if (isEditing) {
      setEditableLead(lead); // Set lead for editing
      onEditOpen();
    } else {
      onViewOpen();
    }
  };

  const handleSave = async () => {
    if (editableLead && editableLead._id) {
      await dispatch(EditLeads(token, editableLead._id, editableLead));
      dispatch(GetProposal(token));
      console.log("Save lead: ", editableLead);
      onEditClose();
    } else {
      console.log("No lead selected for editing.");
    }
  };

  const assignee = ["Unassigned", "Name 1", "Name 2", "Name 3", "Name 4"];

  const filteredAssignee = assignee.filter((assignee) =>
    assignee.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleAssigneeSelect = (assignee) => {
    setSelectedAssignee(assignee);
    setSearchInput("");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Box
      boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
      borderRadius={7}
      w="full"
    >
      <Flex p={3} mb={4}>
        <Heading
          color={"#151617"}
          as="h5"
          fontSize={"18px"}
          fontWeight={"bold"}
          fontFamily={"Public Sans, sans-serif"}
        >
          {getAllproposal?.leads?.length} record
          {getAllproposal?.leads?.length !== 1 ? "s" : ""} found
        </Heading>
      </Flex>
      <TableContainer>
        <Table>
          <Thead>
            <Tr backgroundColor={"#4b4b4b"}>
              <Th>
                <Checkbox />
              </Th>
              <Th color={"white"}>SL No.</Th>
              {/* <Th color={"white"}>Date</Th> */}
              <Th></Th>
              <Th color={"white"}>ID</Th>
              <Th color={"white"}>Name</Th>
              <Th color={"white"}>Email Address</Th>
              <Th color={"white"}>Phone Number</Th>
              {/* <Th color={"white"}>Alternate Contact</Th>
                  <Th color={"white"}>Deal Stage</Th>
                  {/* <Th color={"white"}>WhatsApp Number</Th> */}
              {/* <Th color={"white"}>Company Name</Th>
                  <Th color={"white"}>Company Address</Th>
                  <Th color={"white"}>City</Th>
                  <Th color={"white"}>State</Th>
                  <Th color={"white"}>Expected Closure Date</Th>
                  <Th color={"white"}>Country</Th>
                  <Th color={"white"}>Website</Th>
                  <Th color={"white"}>Number of Employees</Th>
                  <Th color={"white"}>Nature of Business</Th>
                  <Th color={"white"}>Company Registration Type</Th>
                  <Th color={"white"}>Standard</Th>
                  <Th color={"white"}>Executive</Th>
                  <Th color={"white"}>Service Type</Th>
                  <Th color={"white"}>Closure Probability</Th>
                  <Th color={"white"}>Closure Value</Th>
                  <Th color={"white"}>Gross Value</Th> */}
              <Th color={"white"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getAllproposal?.leads?.map((Leads, index) => (
              <Tr key={Leads.id}>
                <Td>
                  <Checkbox />
                </Td>
                <Td>{index + 1}</Td>
                <Td>
                  <EditIcon
                    cursor="pointer"
                    mr={2}
                    onClick={() => handleLeadClick(Leads, true)} // Open in edit mode
                  />
                  <ViewIcon
                    cursor="pointer"
                    onClick={() => handleLeadClick(Leads)} // Open in view mode
                  />
                </Td>
                {/* <Td>{Leads.date}</Td> */}
                <Td>{Leads._id.slice(-6)}</Td>
                <Td>{Leads.name}</Td>
                <Td>{Leads.emailAddress}</Td>
                <Td>{Leads.phoneNumber}</Td>
                {/* <Td>{Leads.alternateContact}</Td>
                    <Td>{Leads.status}</Td> */}
                {/* <Td>{Leads.state}</Td> */}
                {/* <Td>{}</Td>
                    <Td>{}</Td>
                    <Td>{Leads.state}</Td>
                    <Td>{Leads.state}</Td>
                    <Td>{Leads.createdAt}</Td> */}
                {/* <Td>{Leads.country}</Td>
                    <Td>{Leads.companyWebsite}</Td>
                    <Td>{Leads.totalNumberOfEmployees}</Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td>{Leads.executive}</Td>
                    <Td>{Leads.serviceType}</Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td> */}
                <Td>
                  <Select
                    w={"150px"}
                    placeholder="Select option"
                    value={leadActions.status}
                    onChange={(e) =>
                      handleActionChange(Leads._id, e.target.value)
                    }
                  >
                    <option value="Submit for review">Submit for review</option>
                  </Select>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Drawer
        isOpen={isViewOpen}
        placement="right"
        onClose={onViewClose}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={"red"}>View Details</DrawerHeader>

          <DrawerBody>
            {selectedLead && (
              <Box
                p={4}
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                mb={4}
              >
                <Box ml={10}>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      ID:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead._id}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Date:
                    </Text>
                    <Text as="span" ml={2}>
                      {formatDate(selectedLead.date)}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Name:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.name}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Email Address:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.emailAddress}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Phone Number:{" "}
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.phoneNumber}
                    </Text>
                  </Text>

                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Alternate Contact:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.alternateContact}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Executive:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.executive}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Deal Stage:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.dealStage}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      WhatsApp Number:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.whatsAppNumber}
                    </Text>
                  </Text>

                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Company Name:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.companyName}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Company Address:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.companyAddress}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      City:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.city}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      State:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.state}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Expected Closure Date:
                    </Text>
                    <Text as="span" ml={2}>
                      {formatDate(selectedLead.expectedClosureDate)}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Country:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.country}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Website:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.website}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Number of Employees:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.numberOfEmployees}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Nature of Business:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.natureOfBusiness}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Company Registration Type:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.companyRegistrationType}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Standard:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.standard}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Service Type:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.serviceType}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Closure Probability:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.closureProbability}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Closure Value:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.closureValue}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Gross Value:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.grossValue}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Action:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.action}
                    </Text>
                  </Text>
                </Box>
              </Box>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={onViewClose}>Close</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isEditOpen}
        placement="right"
        onClose={onEditClose}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={"red"}>Details</DrawerHeader>

          <DrawerBody>
            {editableLead && (
              <Tabs variant="soft-rounded" colorScheme="red">
                <TabList>
                  <Tab>Edit Details</Tab>
                </TabList>

                <TabPanels>
                  {/* Edit Details tab */}
                  <TabPanel>
                    <SimpleGrid columns={2} spacing={4}>
                      <Box>
                        <Text fontWeight="bold">ID:</Text>
                        <Input
                          value={editableLead._id}
                          name="_id"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Date:</Text>
                        <Input
                          value={formatDate(editableLead.date)}
                          name="date"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Name:</Text>
                        <Input
                          value={editableLead.name}
                          name="name"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Email Address:</Text>
                        <Input
                          value={editableLead.emailAddress}
                          name="emailAddress"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Phone Number:</Text>
                        <Input
                          value={editableLead.phoneNumber}
                          name="phoneNumber"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Alternate Contact:</Text>
                        <Input
                          value={editableLead.alternateContact}
                          name="alternateContact"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Executive:</Text>
                        <Input
                          value={editableLead.executive}
                          name="executive"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Deal Stage:</Text>
                        <Input
                          value={editableLead.dealStage}
                          name="dealStage"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">WhatsApp Number:</Text>
                        <Input
                          value={editableLead.whatsAppNumber}
                          name="whatsAppNumber"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Company Name:</Text>
                        <Input
                          value={editableLead.companyName}
                          name="companyName"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Company Address:</Text>
                        <Input
                          value={editableLead.companyAddress}
                          name="country"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">City:</Text>
                        <Input
                          value={editableLead.city}
                          name="city"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">State:</Text>
                        <Input
                          value={editableLead.state}
                          name="state"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Expected Closure Date:</Text>
                        <Input
                          value={formatDate(editableLead.expectedClosureDate)}
                          name="expectedClosureDate"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Country:</Text>
                        <Input
                          value={editableLead.country}
                          name="country"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Website:</Text>
                        <Input
                          value={editableLead.website}
                          name="website"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">
                          Total Number Of Employees:
                        </Text>
                        <Input
                          value={editableLead.totalNumberOfEmployees}
                          name="totalNumberOfEmployees"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Nature of Business:</Text>
                        <Input
                          value={editableLead.natureOfBusiness}
                          name="natureOfBusiness"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">
                          Company Registration Type:
                        </Text>
                        <Input
                          value={editableLead.companyRegistrationType}
                          name="companyRegistrationType"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Standard:</Text>
                        <Input
                          value={editableLead.standard}
                          name="standard"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Service Type:</Text>
                        <Input
                          value={editableLead.serviceType}
                          name="serviceType"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Closure Probability:</Text>
                        <Input
                          value={editableLead.closureProbability}
                          name="closureProbability"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Closure Value:</Text>
                        <Input
                          value={editableLead.closureValue}
                          name="closureValue"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Gross Value:</Text>
                        <Input
                          value={editableLead.grossValue}
                          name="grossValue"
                          onChange={handleInputChange}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Action:</Text>
                        <Input
                          value={editableLead.action}
                          name="action"
                          onChange={handleInputChange}
                        />
                      </Box>
                      {/* Add other fields to edit here */}
                    </SimpleGrid>
                  </TabPanel>

                  {/* Details tab */}
                </TabPanels>
              </Tabs>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={onEditClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default UserProposals;
