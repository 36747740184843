import { Box, Grid, HStack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { TbRotateRectangle } from "react-icons/tb";
import { LuTrees } from "react-icons/lu";
import { TbBrandSurfshark } from "react-icons/tb";
import { BsClock } from "react-icons/bs";
import { HiOutlineBriefcase } from "react-icons/hi2";
import Graph from "./UserGraph";
import UserGraph from "./UserGraph";

const UserDashboard = () => {
  const [closures, setClosures] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const newClosures = Math.floor(Math.random() * 10);
      if (newClosures !== closures) {
        setIsFlipped(true);
        setTimeout(() => {
          setClosures(newClosures);
          setIsFlipped(false);
        }, 600);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [closures]);

  return (
    <Box w="full">
      <Grid templateColumns="repeat(6, 1fr)" gap={4}>
        <Box
          height={"150px"}
          padding={5}
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius="10px"
        >
          <HStack>
            <Box
              height={"45px"}
              width={"45px"}
              background={"#fff1e3 !important"}
              borderRadius={"5px"}
              p={1}
            >
              <Box m={"5px"}>
                <TbRotateRectangle size={25} color="#ff9f43" />{" "}
              </Box>
            </Box>{" "}
            <Text fontSize={"20px"} ml={"5px"}>
              0
            </Text>{" "}
          </HStack>
          <Text mt={"15px"}>Proposal Shared</Text>
        </Box>
        <Box
          height={"150px"}
          padding={5}
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius="10px"
        >
          <HStack>
            <Box
              height={"45px"}
              width={"45px"}
              background={"#dff7e9 !important"}
              borderRadius={"5px"}
              p={1}
            >
              <Box m={"5px"}>
                <LuTrees size={25} color="#28c76f" />{" "}
              </Box>
            </Box>{" "}
            <Text fontSize={"20px"} ml={"5px"}>
              0
            </Text>{" "}
          </HStack>
          <Text mt={"15px"}>Today’s Active</Text>
        </Box>
        <Box
          height={"150px"}
          padding={5}
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius="10px"
        >
          <HStack>
            <Box
              height={"45px"}
              width={"45px"}
              background={"#d9f8fc !important"}
              borderRadius={"5px"}
              p={1}
            >
              <Box m={"5px"}>
                <TbBrandSurfshark size={25} color="#00cfe8" />{" "}
              </Box>
            </Box>{" "}
            <Text fontSize={"20px"} ml={"5px"}>
              0
            </Text>{" "}
          </HStack>
          <Text mt={"15px"}> Today's Prospective</Text>
        </Box>
        <Box
          height={"150px"}
          padding={5}
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius="10px"
        >
          <HStack>
            {" "}
            <Box
              height={"45px"}
              width={"45px"}
              background={"#fce5e6 !important"}
              borderRadius={"5px"}
              p={1}
            >
              <Box m={"5px"}>
                <BsClock size={25} color="#ea5455" />
              </Box>
            </Box>{" "}
            <Text fontSize={"20px"} ml={"5px"}>
              0
            </Text>{" "}
          </HStack>
          <Text mt={"15px"}>Today’s Pipeline</Text>
        </Box>
        <Box
          height={"150px"}
          padding={5}
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius="10px"
        >
          <HStack>
            {" "}
            <Box
              height={"45px"}
              width={"45px"}
              background={"#eae8fd !important"}
              borderRadius={"5px"}
              p={1}
            >
              <Box m={"5px"}>
                <HiOutlineBriefcase size={25} color="#7367f0" />{" "}
              </Box>
            </Box>{" "}
            <Text fontSize={"20px"} ml={"5px"}>
              0
            </Text>{" "}
          </HStack>
          <Text mt={"15px"}>Today’s Dump</Text>
        </Box>
        <Box
          height={"150px"}
          padding={5}
          perspective="1000px"
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius="10px"
        >
          <Box
            height={"80%"}
            width={"100%"}
            position="relative"
            transform={isFlipped ? "rotateY(0deg)" : "rotateY(180deg)"}
            transformStyle="preserve-3d"
            transition="transform 0.6s"
          >
            <Box
              height={"100%"}
              width={"100%"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              backfaceVisibility="hidden"
              background={"#fff1e3"}
              borderRadius="7px"
            >
              <Text fontSize="2xl" fontWeight="bold" color={"#ff6347"}>
                {closures}
              </Text>
            </Box>
            <Box
              height={"100%"}
              width={"100%"}
              position="absolute"
              top="0"
              left="0"
              display="flex"
              justifyContent="center"
              alignItems="center"
              backfaceVisibility="hidden"
              transform="rotateY(180deg)"
              bg="linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)"
              borderRadius="7px"
            >
              <Text fontSize="2xl" fontWeight="bold" color="white">
                1
              </Text>
            </Box>
          </Box>
          <Text mt={"10px"}>Today's Closures</Text>
        </Box>
      </Grid>
      <Box>
        <UserGraph />
      </Box>
    </Box>
  );
};

export default UserDashboard;
