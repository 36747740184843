import React, { useState } from "react";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import {
  IoMdArrowDropdown,
  IoMdArrowDropright,
  IoMdSend,
} from "react-icons/io";
import { FiPlusCircle, FiMinusCircle, FiCheck, FiX } from "react-icons/fi";
import { ImAttachment } from "react-icons/im";
import { CiImageOn } from "react-icons/ci";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdEdit } from "react-icons/md";

const ManagerChat = ({ isSidebarExpanded }) => {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: "John Doe",
      avatar: "https://bit.ly/dan-abramov",
      recentMessage: "Hey there! Let’s catch up soon.",
      lastChatTime: "10:45 AM",
      chats: [
        { sender: "John", message: "Hey! How are you?", time: "10:30 AM" },
        {
          sender: "You",
          message: "I’m good, thanks! How about you?",
          time: "10:31 AM",
        },
        {
          sender: "John",
          message: "Doing well. Let’s meet this weekend.",
          time: "10:35 AM",
        },
      ],
    },
    {
      id: 2,
      name: "Jane Smith",
      avatar: "https://bit.ly/ryan-florence",
      recentMessage: "Did you get my email?",
      lastChatTime: "11:30 AM",
      chats: [
        { sender: "Jane", message: "Did you get my email?", time: "11:00 AM" },
        {
          sender: "You",
          message: "Yes, I’ll review it and get back to you.",
          time: "11:05 AM",
        },
        {
          sender: "Jane",
          message: "Thanks! Looking forward to it.",
          time: "11:15 AM",
        },
      ],
    },
    {
      id: 3,
      name: "Mark Taylor",
      avatar: "https://bit.ly/prosper-baba",
      recentMessage: "Let’s schedule a meeting.",
      lastChatTime: "12:15 PM",
      chats: [
        {
          sender: "Mark",
          message: "Let’s schedule a meeting.",
          time: "12:10 PM",
        },
        {
          sender: "You",
          message: "Sure, what time works for you?",
          time: "12:12 PM",
        },
        {
          sender: "Mark",
          message: "How about tomorrow at 3 PM?",
          time: "12:13 PM",
        },
      ],
    },
    {
      id: 4,
      name: "Emily Davis",
      avatar: "https://bit.ly/tioluwani-kolawole",
      recentMessage: "Great job on the presentation!",
      lastChatTime: "1:20 PM",
      chats: [
        {
          sender: "Emily",
          message: "Great job on the presentation!",
          time: "1:15 PM",
        },
        {
          sender: "You",
          message: "Thank you, Emily!",
          time: "1:17 PM",
        },
      ],
    },
    {
      id: 5,
      name: "Michael Lee",
      avatar: "https://bit.ly/code-beast",
      recentMessage: "Can you review the report?",
      lastChatTime: "2:05 PM",
      chats: [
        {
          sender: "Michael",
          message: "Can you review the report?",
          time: "2:00 PM",
        },
      ],
    },
    {
      id: 6,
      name: "Sophia Brown",
      avatar: "https://bit.ly/ryan-florence",
      recentMessage: "Let’s finalize the design.",
      lastChatTime: "2:45 PM",
      chats: [
        {
          sender: "Sophia",
          message: "Let’s finalize the design.",
          time: "2:40 PM",
        },
      ],
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(users[0]);
  const [message, setMessage] = useState("");
  const [editMessageId, setEditMessageId] = useState(null);
  const [editMessageContent, setEditMessageContent] = useState("");
  const [hoveredMessage, setHoveredMessage] = useState(null);
  const [replyTo, setReplyTo] = useState(null);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [isChatListVisible, setIsChatListVisible] = useState(true);
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = {
        sender: "You",
        message: replyTo
          ? `Reply to: "${replyTo.message}" ${message}`
          : message,
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      setSelectedUser((prev) => ({
        ...prev,
        chats: [...prev.chats, newMessage],
      }));

      setUsers((prevUsers) => {
        const updatedUsers = prevUsers.map((user) =>
          user.id === selectedUser.id
            ? {
                ...user,
                recentMessage: newMessage.message,
                lastChatTime: newMessage.time,
                chats: [...user.chats, newMessage],
              }
            : user
        );

        const selectedUserIndex = updatedUsers.findIndex(
          (user) => user.id === selectedUser.id
        );
        const [selectedUserObj] = updatedUsers.splice(selectedUserIndex, 1);
        return [selectedUserObj, ...updatedUsers];
      });

      setMessage("");
      setReplyTo(null);
    }
  };

  const handleEditMessage = () => {
    if (editMessageId !== null && editMessageContent.trim()) {
      const updatedMessage = {
        sender: "You",
        message: editMessageContent,
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      setSelectedUser((prev) => ({
        ...prev,
        chats: prev.chats.map((chat, index) =>
          index === editMessageId ? updatedMessage : chat
        ),
      }));

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser.id
            ? {
                ...user,
                chats: user.chats.map((chat, index) =>
                  index === editMessageId ? updatedMessage : chat
                ),
              }
            : user
        )
      );

      setEditMessageContent("");
      setEditMessageId(null);
    }
  };

  const handleEditClick = (index, content) => {
    setEditMessageId(index);
    setEditMessageContent(content);
  };

  const handleReply = (chat) => {
    setReplyTo(chat); // Highlight the message being replied to
    setMessage(`Reply to: "${chat.message}" `); // Prefill the message input
  };

  const handleForward = (chat) => {
    setMessage(`Forwarded message: "${chat.message}" `); // Prefill the message input
  };

  const handleDelete = (index) => {
    setSelectedUser((prev) => ({
      ...prev,
      chats: prev.chats.filter((_, i) => i !== index),
    }));

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === selectedUser.id
          ? { ...user, chats: user.chats.filter((_, i) => i !== index) }
          : user
      )
    );
  };

  const getRecentMessagePreview = (user) => {
    const lastChat = user.chats[user.chats.length - 1];
    return lastChat ? `${lastChat.sender}: ${lastChat.message}` : "";
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box boxShadow="0 0 10px rgba(128, 128, 128, 0.5)" borderRadius={7}>
      <Flex p={4} gap={4} flexWrap="wrap">
        <Box
          w="30%"
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="sm"
          bg="white"
          overflowY="auto"
          maxHeight="70vh"
          sx={{
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#D3D3D3",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "darkgray",
            },
            "&::-webkit-scrollbar-track": {
              background: "white",
            },
          }}
        >
          <Flex alignItems="center" mb={4} justifyContent="space-between">
            <Flex
              alignItems="center"
              cursor="pointer"
              onClick={() => setIsChatListVisible(!isChatListVisible)}
            >
              {isChatListVisible ? (
                <IoMdArrowDropright style={{ marginRight: "3px" }} />
              ) : (
                <IoMdArrowDropdown style={{ marginRight: "3px" }} />
              )}
              <Text color={"#848484"}>Recent</Text>
            </Flex>
            <Input
              placeholder="Search user to chat..."
              size="sm"
              width="60%"
              borderRadius={7}
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </Flex>
          {isChatListVisible &&
            (showAllUsers ? filteredUsers : filteredUsers.slice(0, 4)).map(
              (user) => (
                <Flex
                  key={user.id}
                  alignItems="center"
                  mb={4}
                  p={2}
                  borderRadius="md"
                  cursor="pointer"
                  bg={selectedUser?.id === user.id ? "blue.50" : "white"}
                  boxShadow={
                    selectedUser?.id === user.id
                      ? "0 0 10px rgba(128, 128, 128, 0.5)"
                      : "none"
                  }
                  _hover={{
                    bg: "gray.100",
                    boxShadow: "0 0 10px rgba(128, 128, 128, 0.5)",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    setSelectedUser(user);
                    setSearchTerm("");
                  }}
                  transition="all 0.2s ease-in-out"
                >
                  <Avatar name={user.name} src={user.avatar} />
                  <Box ml={4} flex="1" overflow="hidden">
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Text
                        fontWeight="bold"
                        color={"#848484"}
                        isTruncated
                        noOfLines={1}
                        title={user.name}
                      >
                        {user.name}
                      </Text>
                      <Text
                        fontSize="xs"
                        color="gray.400"
                        ml={2}
                        whiteSpace="nowrap"
                      >
                        {user.lastChatTime}
                      </Text>
                    </Flex>
                    <Text
                      fontSize="sm"
                      color="gray.500"
                      isTruncated
                      noOfLines={1}
                      title={getRecentMessagePreview(user)}
                    >
                      {getRecentMessagePreview(user)}
                    </Text>
                  </Box>
                </Flex>
              )
            )}
          <Flex justifyContent="center" mt={4}>
            <IconButton
              icon={
                showAllUsers ? (
                  <FiMinusCircle size={25} />
                ) : (
                  <FiPlusCircle size={25} />
                )
              }
              onClick={() => setShowAllUsers(!showAllUsers)}
              background={"none"}
              aria-label={showAllUsers ? "Collapse Users" : "Expand Users"}
            />
          </Flex>
        </Box>

        <Box
          w="68%"
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="sm"
          bg="white"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="70vh"
        >
          {selectedUser ? (
            <>
              <Flex
                alignItems="center"
                mb={4}
                borderBottom="1px solid #E2E8F0"
                pb={4}
              >
                <Avatar name={selectedUser.name} src={selectedUser.avatar} />
                <Box ml={4}>
                  <Flex>
                    <Heading size="md" mt={3}>
                      {selectedUser.name}
                    </Heading>
                    <Tabs ml="8">
                      <TabList>
                        <Tab>Chat</Tab>
                        <Tab>Files</Tab>
                        <Tab>Images</Tab>
                      </TabList>
                    </Tabs>
                  </Flex>
                </Box>
              </Flex>

              <Box
                flexGrow={1}
                overflowY="auto"
                mb={4}
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#D3D3D3",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "darkgray",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "white",
                  },
                }}
              >
                {selectedUser.chats.map((chat, index) => (
                  <Flex
                    key={index}
                    alignItems="center"
                    justifyContent={
                      chat.sender === "You" ? "flex-end" : "flex-start"
                    }
                    position="relative"
                    mb={2}
                    onMouseEnter={() => setHoveredMessage(index)}
                    onMouseLeave={() => setHoveredMessage(null)}
                  >
                    {chat.sender === "You" ? (
                      <>
                        <Text
                          as="span"
                          fontSize="xs"
                          color="gray.500"
                          mr={2}
                          mb={3}
                          alignSelf="flex-end"
                        >
                          {chat.time}
                        </Text>
                        {editMessageId === index ? (
                          <Box position="relative" width="80%">
                            <Input
                              value={editMessageContent}
                              onChange={(e) =>
                                setEditMessageContent(e.target.value)
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") handleEditMessage();
                              }}
                              maxWidth="85%"
                              bg="blue.100"
                              px={3}
                              py={2}
                              borderRadius="md"
                            />
                            <Box
                              position="absolute"
                              bottom="2px"
                              right="8px"
                              display="flex"
                              gap={2}
                            >
                              <IconButton
                                icon={<FiCheck />}
                                aria-label="Confirm Edit"
                                size="sm"
                                onClick={handleEditMessage}
                              />
                              <IconButton
                                icon={<FiX />}
                                aria-label="Cancel Edit"
                                size="sm"
                                onClick={() => {
                                  setEditMessageId(null);
                                  setEditMessageContent("");
                                }}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Text
                            bg="blue.100"
                            px={3}
                            py={2}
                            borderRadius="md"
                            maxWidth="80%"
                            textAlign="left"
                          >
                            {chat.message}
                            {hoveredMessage === index && (
                              <Box
                                className="popup-box"
                                position="absolute"
                                top="-32px"
                                right="0"
                                bg="white"
                                borderRadius="md"
                                boxShadow="md"
                                p={1}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-around"
                                width="auto"
                                zIndex={1000}
                              >
                                <IconButton
                                  icon={<MdEdit size={15} />}
                                  aria-label="Edit Message"
                                  size="xs"
                                  variant="ghost"
                                  onClick={() =>
                                    handleEditClick(index, chat.message)
                                  }
                                />
                                <Menu>
                                  <MenuButton
                                    as={IconButton}
                                    icon={<HiDotsHorizontal size={15} />}
                                    variant="ghost"
                                    aria-label="More Options"
                                    size="xs"
                                  />
                                  <MenuList>
                                    <MenuItem onClick={() => handleReply(chat)}>
                                      Reply
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => handleForward(chat)}
                                    >
                                      Forward
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => handleDelete(index)}
                                    >
                                      Delete
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Box>
                            )}
                          </Text>
                        )}
                      </>
                    ) : (
                      <>
                        <Text
                          bg="gray.100"
                          px={3}
                          py={2}
                          borderRadius="md"
                          maxWidth="80%"
                          textAlign="left"
                        >
                          {chat.message}
                          {hoveredMessage === index && (
                            <Box
                              position="absolute"
                              top="-32px"
                              left="0"
                              bg="white"
                              borderRadius="md"
                              boxShadow="md"
                              p={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-around"
                              width="80px"
                              zIndex={1000}
                            >
                              <Menu>
                                <MenuButton
                                  as={IconButton}
                                  icon={<HiDotsHorizontal size={15} />}
                                  variant="ghost"
                                  aria-label="More Options"
                                  size="xs"
                                />
                                <MenuList>
                                  <MenuItem onClick={() => handleReply(chat)}>
                                    Reply
                                  </MenuItem>
                                  <MenuItem onClick={() => handleForward(chat)}>
                                    Forward
                                  </MenuItem>
                                  <MenuItem onClick={() => handleDelete(index)}>
                                    Delete
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Box>
                          )}
                        </Text>
                        <Text
                          as="span"
                          fontSize="xs"
                          color="gray.500"
                          ml={2}
                          mb={3}
                          alignSelf="flex-end"
                        >
                          {chat.time}
                        </Text>
                      </>
                    )}
                  </Flex>
                ))}
              </Box>

              <Flex mt={4} position="relative">
                <Input
                  placeholder="Type your message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                  flex={1}
                  mr={2}
                  pr="140px"
                />
                <Box
                  position="absolute"
                  right="10px"
                  top="50%"
                  transform="translateY(-50%)"
                  display="flex"
                  alignItems="center"
                >
                  <IconButton
                    icon={<ImAttachment size={20} />}
                    aria-label="Attach File"
                    size="sm"
                    variant="ghost"
                  />
                  <IconButton
                    icon={<CiImageOn size={20} />}
                    aria-label="Upload Image"
                    size="sm"
                    variant="ghost"
                    ml={2}
                  />

                  <IconButton
                    aria-label="Send Message"
                    icon={<IoMdSend size={20} />}
                    onClick={handleSendMessage}
                    ml={2}
                  />
                </Box>
              </Flex>
            </>
          ) : (
            <Text>Select a user to view their chat.</Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ManagerChat;
