import {
  Box,
  Button,
  Flex,
  Heading,
  useColorMode,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Icon,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { TbMoonStars, TbUserCircle, TbSettings } from "react-icons/tb";
import { AiOutlineSun, AiOutlineLogout } from "react-icons/ai";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { TbUrgent } from "react-icons/tb";
import { IoNotificationsOutline } from "react-icons/io5";
import { MdBusiness } from "react-icons/md";
import { ChevronDownIcon, DragHandleIcon } from "@chakra-ui/icons";
import UserImage from "../Images/UserImage.jpg";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/AuthReducer/Action";
import Logo from "../../Components/Images/Certvalue-Logo.webp";

const Navbar = ({ title }) => {
  const [isToggled, setIsToggled] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const handleToggle = () => {
    setIsToggled(!isToggled);
    toggleColorMode();
  };

  const handleSettings = (e) => {
    e.preventDefault();
    navigate("/settings");
  };
  const handleProfile = (e) => {
    e.preventDefault();
    navigate("/myprofile");
  };

  const handleCompanyProfile = (e) => {
    e.preventDefault();
    navigate("/companyprofile");
  };

  return (
    <Flex
      width="100%"
      align="center"
      height={20}
      backgroundColor={"white"}
      px="20px"
      justifyContent="space-between"
      position="relative"
    >
      <Flex gap="7" align="center">
        <Box w="200px">
          <Image src={Logo} w="full" />
        </Box>
      </Flex>
      {/* <Box>
        <Heading as="h2" fontSize={"22px"}>
          {title}
        </Heading>
      </Box> */}
      <Flex direction="row" align="center" gap="7">
        <HiOutlineViewGridAdd color="black" size={25} />
        <TbUrgent color="black" size={25} />
        <IoNotificationsOutline color="black" size={25} />
        <Box>
          <Menu>
            <MenuButton as={Button} variant="unstyled">
              <img
                vertical-align={"middle"}
                width={"40px"}
                height={"40px"}
                border-radius={"50px"}
                object-fit={"cover"}
                className="image-profile"
                src={UserImage}
                alt="hr-logo"
              />
            </MenuButton>
            <MenuList>
              <MenuItem
                color={"#5D596c"}
                _hover={{ bg: "#D8000026", color: "#7367f0" }}
                icon={<TbUserCircle size={20} />}
                onClick={handleProfile}
              >
                My Profile
              </MenuItem>
              <MenuItem
                color={"#5D596c"}
                _hover={{ bg: "#D8000026", color: "#7367f0" }}
                icon={<MdBusiness size={20} />}
                onClick={handleCompanyProfile}
              >
                Company Profile
              </MenuItem>
              <MenuItem
                color={"#5D596c"}
                _hover={{ bg: "#D8000026", color: "#7367f0" }}
                icon={<TbSettings size={20} />}
                onClick={handleSettings}
              >
                Settings
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => dispatch(logout(toast, navigate))}
                color={"#5D596c"}
                _hover={{ bg: "#D8000026", color: "#7367f0" }}
                icon={<AiOutlineLogout size={20} />}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Navbar;
