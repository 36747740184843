import React, { useState } from "react";
import { Box, Flex, IconButton, VStack, Button, Icon } from "@chakra-ui/react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import { AiOutlineDashboard } from "react-icons/ai";
import { LiaTasksSolid, LiaProjectDiagramSolid } from "react-icons/lia";
import { TbCheckbox } from "react-icons/tb";
import { IoRocketOutline } from "react-icons/io5";
import { BsBag, BsFileEarmarkText } from "react-icons/bs";
import { PiNewspaperLight, PiUsersLight } from "react-icons/pi";
import { BiChat } from "react-icons/bi";
import { MdOutlineCircle } from "react-icons/md";
import { NavLink } from "react-router";

const navItems = [
  { name: "Dashboard", icon: AiOutlineDashboard, path: "home" },
  { name: "Add New Lead", icon: LiaTasksSolid, path: "addnewlead" },
  { name: "Fresh", icon: TbCheckbox, path: "fresh" },
  { name: "Enquiries", icon: IoRocketOutline, path: "enquiries" },
  { name: "Projects", icon: BsBag, path: "projects" },
  { name: "Customers", icon: PiNewspaperLight, path: "customers" },
  { name: "Finance", icon: LiaProjectDiagramSolid, path: "finance" },
  { name: "Report", icon: BsFileEarmarkText, path: "report" },
  { name: "Users", icon: PiUsersLight, path: "users" },
  { name: "Chat", icon: BiChat, path: "chat" },
];

const enquirySubItems = [
  {
    name: "Proposal Shared",
    icon: MdOutlineCircle,
    path: "enquiries/proposal_shared",
  },
  { name: "Active", icon: MdOutlineCircle, path: "enquiries/active" },
  { name: "Prospective", icon: MdOutlineCircle, path: "enquiries/prospective" },
  { name: "Pipeline", icon: MdOutlineCircle, path: "enquiries/pipeline" },
  { name: "Dump", icon: MdOutlineCircle, path: "enquiries/dump" },
];

const financeSubItems = [
  { name: "Income", icon: MdOutlineCircle, path: "finance/income" },
  { name: "Statistics", icon: MdOutlineCircle, path: "finance/statistics" },
  { name: "Approvals", icon: MdOutlineCircle, path: "finance/approvals" },
];

const SidebarItem = ({
  icon,
  label,
  isCollapsed,
  onClick,
  hasSubItems,
  isOpen,
  isSubItem,
  path,
}) => (
  <NavLink
    to={path}
    style={({ isActive }) => ({
      width: "100%",
      borderRadius: "8px",
      background: isActive
        ? "linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)"
        : "transparent",
    })}
  >
    <Button
      variant="ghost"
      justifyContent={isCollapsed ? "center" : "flex-start"}
      w="full"
      leftIcon={
        !isCollapsed ? (
          <Icon as={icon} boxSize={isSubItem ? 4 : 6} />
        ) : undefined
      }
      onClick={onClick}
      _hover={{
        bgGradient: "linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)",
        color: "white",
      }}
      rightIcon={
        hasSubItems && !isCollapsed ? (
          <Icon as={isOpen ? FiChevronUp : FiChevronDown} boxSize={5} />
        ) : null
      }
    >
      {isCollapsed ? <Icon as={icon} boxSize={isSubItem ? 4 : 6} /> : label}
    </Button>
  </NavLink>
);

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openItem, setOpenItem] = useState(null);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleItemClick = (name) => {
    setOpenItem(openItem === name ? null : name);
  };

  return (
    <Box
      as="nav"
      h="100%"
      bg="white"
      borderRightWidth="2px"
      borderRightStyle="solid"
      borderRightColor="gray.600"
      w={isCollapsed ? "80px" : "250px"}
      transition="width 0.3s ease"
      overflowY="auto"
    >
      <Flex direction="column" h="100%" p="10px">
        <Flex justify={isCollapsed ? "center" : "end"} align="center">
          <IconButton
            icon={isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
            onClick={toggleSidebar}
            size="sm"
            aria-label="Toggle sidebar"
            variant="ghost"
            border="1px solid gray.600"
            borderRadius="md"
          />
        </Flex>

        <VStack spacing={1} align="center" flex={1}>
          {navItems.map((item) => (
            <React.Fragment key={item.name}>
              <SidebarItem
                icon={item.icon}
                label={item.name}
                isCollapsed={isCollapsed}
                onClick={() => handleItemClick(item.name)}
                hasSubItems={
                  item.name === "Enquiries" || item.name === "Finance"
                }
                isOpen={openItem === item.name}
                path={item.path}
              />
              {item.name === "Enquiries" &&
                openItem === "Enquiries" &&
                !isCollapsed && (
                  <VStack pl={7} spacing={1} align="stretch">
                    {enquirySubItems.map((subItem) => (
                      <SidebarItem
                        key={subItem.name}
                        icon={subItem.icon}
                        label={subItem.name}
                        isCollapsed={isCollapsed}
                        isSubItem={true}
                        path={subItem.path}
                      />
                    ))}
                  </VStack>
                )}
              {item.name === "Finance" &&
                openItem === "Finance" &&
                !isCollapsed && (
                  <VStack pl={7} spacing={1} align="stretch">
                    {financeSubItems.map((subItem) => (
                      <SidebarItem
                        key={subItem.name}
                        icon={subItem.icon}
                        label={subItem.name}
                        isCollapsed={isCollapsed}
                        isSubItem={true}
                        path={subItem.path}
                      />
                    ))}
                  </VStack>
                )}
            </React.Fragment>
          ))}
        </VStack>
      </Flex>
    </Box>
  );
};

export default Sidebar;
